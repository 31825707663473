@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Toggle Switch */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.allegToggleSwitch
{
	vertical-align: middle;
	display:inline-block;
	position: relative;
	width:57px;
	white-space:nowrap;
	@include user-select(none);
}

.allegToggleSwitch-checkbox {
	display: none;
}

.allegToggleSwitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius:3px;
	height:20px;
	position:relative;
}
.allegToggleSwitch-inner
{
	width:100%;
	font-size:12px;
	line-height:22px;
	display:inline-block;
	color: #fff;
	box-sizing: border-box;

	&.active {
		padding-left:5px;
		margin-left: -100%;
		@include transition(margin .2s ease-in);
		background-color: $color-font-default;
		color: #fff;
	}

	&.inactive {
		padding-right: 5px;
		margin-left: 0;
		@include transition(margin .2s ease-in);
		background-color: #eee;
		color: #999;
		text-align: right;
	}
}

input.checked + label .allegToggleSwitch-inner.active
{
	margin-left: 0;
	@include transition(margin .2s ease-in);
}

input.checked + label .allegToggleSwitch-inner.inactive
{
	margin-left:0;
	@include transition(margin .2s ease-in);
}

input.checked + label .allegToggleSwitch-switch
{
	margin-left: 60%;
}

.allegToggleSwitch-switch {
	width: 13px;
	height: 12px;
	margin: 3.5px;
	background: #fff;
	border-radius: 3px;
	box-shadow:1px 1px 3px #555;
	position: absolute;
	top: 0;
	bottom: 0;
	@include transition(all .2s ease-in);
}

.allegToggleSwitch-labelText
{
	display: inline-block;
	white-space: nowrap;
	margin-left: 5px;
	cursor: pointer;

	&.toggleOnRight {
		margin-left: 0;
		margin-right: 5px;
	}
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
                    /* GREEN Toggle Switch */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.allegToggleSwitch.greenSwitch {
    width: 30px;

	.allegToggleSwitch-switch {
		background-color: #f5f5f5;
		border-radius: 50%;
		box-shadow: 1px 1px 2px 0 rgba(#000,0.2);
		height: 12px;
		left: -2px;
		position: absolute;
		top: -2px;
		width: 12px;
	}

	.allegToggleSwitch-label {
		border-radius: 7px;
		height: 16px;
	}

	.allegToggleSwitch-inner.active {
		background-color: #0058ff;
		box-shadow: none;
		height: 16px;
	}

	.allegToggleSwitch-inner.inactive {
		background-color: #b2b2b2;
		box-shadow: none;
		height: 16px;
	}

	&.disabled,
	&.disabled + .allegToggleSwitch-labelText {
		filter: alpha(opacity=50);
		opacity: .5;
	}
}


