@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/**
 * Filter overrides when possible
 */
.FilterRC {
	background-color: #D2D2D2;
}