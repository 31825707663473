@import 'spritePositions';
/* search for update with icon_sprites */

@mixin icon_sprites-sprites($imagePath: none, $x: 0, $y: 0, $width: 0, $height: 0,$Margin: 0,$Marginbottom: 0,$display: inline-block) {
    background-image: $imagePath;
    background-position: $x $y;
    width: $width;
    height: $height;
    margin-left: $Margin;
    margin-bottom: $Marginbottom;
    display: $display;
}

.icon_sprites {
    vertical-align: middle;
    cursor: pointer;


    &.icon_sprites-addBlue12 {
        @include icon_sprites-sprites($icon_sprites-addBlue12-left, $icon_sprites-addBlue12-top, 12px, 12px);
    }

    &.icon_sprites-addBlue16 {
        @include icon_sprites-sprites($icon_sprites-addBlue16-left, $icon_sprites-addBlue16-top, 16px, 16px);
    }

    &.icon_sprites-addBlueClear18 {
        @include icon_sprites-sprites($icon_sprites-addBlueClear18-left, $icon_sprites-addBlueClear18-top, 18px, 18px);
    }

    &.icon_sprites-addGray12 {
        @include icon_sprites-sprites($icon_sprites-addGray12-left, $icon_sprites-addGray12-top, 12px, 12px);
    }

    &.icon_sprites-addGray20 {
        @include icon_sprites-sprites(url(Images/SVG/add_circle.svg), 0px, 0px, 20px, 20px);
    }

    &.icon_sprites-arrowDownGray7 {
        @include icon_sprites-sprites($icon_sprites-arrowDownGray7-left, $icon_sprites-arrowDownGray7-top, 7px, 7px);
    }

    &.icon_sprites-arrowRightGray7 {
        @include icon_sprites-sprites($icon_sprites-arrowRightGray7-left, $icon_sprites-arrowRightGray7-top, 7px, 7px);
    }

    &.icon_sprites-calendarDarkGray13 {
        @include icon_sprites-sprites($icon_sprites-calendarDarkGray13-left, $icon_sprites-calendarDarkGray13-top, 13px, 13px);
    }

    &.icon_sprites-calendarDarkGray15 {
        @include icon_sprites-sprites($icon_sprites-calendarDarkGray15-left, $icon_sprites-calendarDarkGray15-top, 15px, 15px);
    }

    &.icon_sprites-calendarGreen18 {
        @include icon_sprites-sprites($icon_sprites-calendarGreen18-left, $icon_sprites-calendarGreen18-top, 18px, 18px);
    }

    &.icon_sprites-closeCircleGray20 {
        @include icon_sprites-sprites(url(Images/SVG/cancelcircle.svg), 0px, 0px, 20px, 20px);
    }

    &.icon_sprites-closeCircleRed20 {
        @include icon_sprites-sprites(url(Images/SVG/cancelcircle.svg), 0px, 0px, 20px, 20px);
    }

    &.icon_sprites-collapse9 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-collapse9-left, $icon_sprites-collapse9-top, 9px, 9px);
    }

    &.icon_sprites-copy10 {
        @include icon_sprites-sprites($icon_sprites-copy10-left, $icon_sprites-copy10-top, 22px, 22px);
    }

    &.icon_sprites-copyBlue18 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-copyBlue18-left, $icon_sprites-copyBlue18-top, 18px, 18px);
    }

    &.icon_sprites-dragDots15 {
        @include icon_sprites-sprites(url(Images/SVG/kabob.svg), 0px, 0px, 33px, 22px,-5px);
    }

    &.icon_sprites-editBlue18 {
        @include icon_sprites-sprites($icon_sprites-editBlue18-left, $icon_sprites-editBlue18-top, 21px, 18px);
    }

    &.icon_sprites-editGray15 {
        @include icon_sprites-sprites($icon_sprites-editGray15-left, $icon_sprites-editGray15-top, 17px, 15px);
    }

    &.icon_sprites-editGreen15 {
        @include icon_sprites-sprites($icon_sprites-editGreen15-left, $icon_sprites-editGreen15-top, 17px, 15px);
    }

    &.icon_sprites-expand9 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-expand9-left, $icon_sprites-expand9-top, 9px, 9px);
    }

    &.icon_sprites-eyeGray12 {
        @include icon_sprites-sprites(url(Images/SVG/eye_visibility.svg), 0px, -1032px, 21px, 21px);
    }

    &.icon_sprites-eyeGrayOff12 {
        @include icon_sprites-sprites(url(Images/SVG/eye_visibility_off.svg), 0px, -1047px, 21px, 19px);
    }

    &.icon_sprites-eyeGreen12 {
        @include icon_sprites-sprites(url(Images/SVG/eye_visibility.svg), 0px, -1032px, 21px, 21px);
    }

    &.icon_sprites-eyeGreenOff12 {
        @include icon_sprites-sprites(url(Images/SVG/eye_visibility_off.svg), 0px, -1047px, 21px, 19px);
    }

    &.icon_sprites-eyeBlue12 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-eyeGreen12-left, $icon_sprites-eyeGreen12-top, 21px, 12px);
    }

    &.icon_sprites-eyeBlue12 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-eyeGreenOff12-left, $icon_sprites-eyeGreenOff12-top, 21px, 14px);
    }

    &.icon_sprites-filterDarkGray12 {
        @include icon_sprites-sprites($icon_sprites-filterDarkGray12-left, $icon_sprites-filterDarkGray12-top, 12px, 12px);
    }

    &.icon_sprites-filterDarkGray15 {
        @include icon_sprites-sprites($icon_sprites-filterDarkGray15-left, $icon_sprites-filterDarkGray15-top, 16px, 16px);
    }

    &.icon_sprites-filterGray14 {
        @include icon_sprites-sprites($icon_sprites-filterGray14-left, $icon_sprites-filterGray14-top, 15px, 14px);
    }

    &.icon_sprites-filterGray16 {
        @include icon_sprites-sprites($icon_sprites-filterGray16-left, $icon_sprites-filterGray16-top, 16px, 16px);
    }

    &.icon_sprites-greenCheck16 {
        @include icon_sprites-sprites($icon_sprites-greenCheck16-left, $icon_sprites-greenCheck16-top, 16px, 16px);
    }

    &.icon_sprites-helpIconWhite18 {
        @include icon_sprites-sprites($icon_sprites-helpIconWhite18-left, $icon_sprites-helpIconWhite18-top, 18px, 18px);
    }

    &.icon_sprites-infoGray12 {
        @include icon_sprites-sprites($icon_sprites-infoGray12-left, $icon_sprites-infoGray12-top, 13px, 12px);
    }

    &.icon_sprites-infoIconGray16 {
        @include icon_sprites-sprites(url(Images/SVG/info.svg), 0px, 0px, 24px, 24px,5px);
    }

    &.icon_sprites-infoIconGreen16 {
        @include icon_sprites-sprites(url(Images/SVG/info.svg), 0px, 0px, 24px, 24px,-2px);
    }

    &.icon_sprites-infoIconOrange16 {
        @include icon_sprites-sprites($icon_sprites-infoIconOrange16-left, $icon_sprites-infoIconOrange16-top, 16px, 16px);
    }

    &.icon_sprites-infoOrange26 {
        @include icon_sprites-sprites(url(Images/SVG/info.svg), 0px, -16px, 20px, 20px, 150px,7px,"block!important");
    }

    &.icon_sprites-menuBlack10 {
        @include icon_sprites-sprites($icon_sprites-menuBlack10-left, $icon_sprites-menuBlack10-top, 14px, 10px);
    }

    &.icon_sprites-menuBlue10 {
        @include icon_sprites-sprites($icon_sprites-menuBlue10-left, $icon_sprites-menuBlue10-top, 14px, 10px);
    }

    &.icon_sprites-moveArrowGray20 {
        @include icon_sprites-sprites(url(Images/SVG/XITextanalyticsarrowright.svg), 0px, 0px, 20px, 20px);
    }

    &.icon_sprites-moveArrowGreen20 {
        @include icon_sprites-sprites(url(Images/SVG/XITextanalyticsarrowright.svg), 0px, 0px, 20px, 20px);
    }

    &.icon_sprites-multiColumn9 {
        @include icon_sprites-sprites(url(Images/SVG/kabob.svg),0px,0px, 20px, 19px);
    }

    &.icon_sprites-pancakeMenuGray15 {
        @include icon_sprites-sprites(url(Images/svg/menu.svg),$icon_sprites-pancakeMenuGray15-left, $icon_sprites-pancakeMenuGray15-top, 27px, 21px);
    }

    &.icon_sprites-pencilBlue12 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-pencilBlue12-left, $icon_sprites-pencilBlue12-top, 13px, 13px);
    }

    &.icon_sprites-pencilGray12 {
        @include icon_sprites-sprites(url(Images/svg/edit.svg), 2px, -1983px, 21px, 20px);
    }

    &.icon_sprites-pencilGreen12 {
        @include icon_sprites-sprites(url(Images/svg/edit.svg), 2px, -2043px, 21px, 20px);
    }

    &.icon_sprites-removeBlue13 {
        @include icon_sprites-sprites($icon_sprites-removeBlue13-left, $icon_sprites-removeBlue13-top, 13px, 13px);
    }

    &.icon_sprites-removeBlue18 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-removeBlue18-left, $icon_sprites-removeBlue18-top, 18px, 18px);
    }

    &.icon_sprites-removeGray13 {
        @include icon_sprites-sprites($icon_sprites-removeGray13-left, $icon_sprites-removeGray13-top, 13px, 13px);
    }

    &.icon_sprites-removeRedClear18 {
        @include icon_sprites-sprites($icon_sprites-removeRedClear18-left, $icon_sprites-removeRedClear18-top, 18px, 18px);
    }

    &.icon_sprites-removeXBlue10 {
        @include icon_sprites-sprites($icon_sprites-removeXBlue10-left, $icon_sprites-removeXBlue10-top, 10px, 10px);
    }

    &.icon_sprites-removeXBlue14 {
        @include icon_sprites-sprites($icon_sprites-removeXBlue14-left, $icon_sprites-removeXBlue14-top, 14px, 14px);
    }

    &.icon_sprites-removeXGray14 {
        @include icon_sprites-sprites($icon_sprites-removeXGray14-left, $icon_sprites-removeXGray14-top, 14px, 14px);
    }

    &.icon_sprites-revertBlue18 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-revertBlue18-left, $icon_sprites-revertBlue18-top, 17px, 18px);
    }

    &.icon_sprites-revertGray18 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-revertGray18-left, $icon_sprites-revertGray18-top, 17px, 18px);
    }

    &.icon_sprites-searchGreen13 {
        @include icon_sprites-sprites($icon_sprites-searchGreen13-left, $icon_sprites-searchGreen13-top, 13px, 13px);
    }

    &.icon_sprites-searchGrey13 {
        @include icon_sprites-sprites(url(Images/svg/search.svg), $icon_sprites-searchGrey13-left, $icon_sprites-searchGrey13-top, 13px, 13px);
    }

    &.icon_sprites-settingsGreen16 {
        @include icon_sprites-sprites(url(Images/SVG/settings_blue.svg),$icon_sprites-settingsGreen16-left, $icon_sprites-settingsGreen16-top, 18px, 17px);
    }

    &.icon_sprites-shareBlue14 {
        @include icon_sprites-sprites($icon_sprites-shareBlue14-left, $icon_sprites-shareBlue14-top, 15px, 14px);
    }

    &.icon_sprites-trash29 {
        @include icon_sprites-sprites($icon_sprites-trash29-left, $icon_sprites-trash29-top, 21px, 23px);
    }

    &.icon_sprites-warningYellow18 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-warningYellow18-left, $icon_sprites-warningYellow18-top, 18px, 18px);
    }

    &.icon_sprites-warningYellow34 {
        @include icon_sprites-sprites(url(Images/Sprites/icon_sprites.png?v=3102016), $icon_sprites-warningYellow34-left, $icon_sprites-warningYellow34-top, 35px, 34px);
    }

    &.icon_sprites-xGray19 {
        @include icon_sprites-sprites($icon_sprites-xGray19-left, $icon_sprites-xGray19-top, 19px, 19px);
    }

    &.icon_sprites-xGray8 {
        @include icon_sprites-sprites($icon_sprites-xGray8-left, $icon_sprites-xGray8-top, 8px, 8px);
    }

    &.icon_sprites-xLightGray8 {
        @include icon_sprites-sprites($icon_sprites-xLightGray8-left, $icon_sprites-xLightGray8-top, 8px, 8px);
    }

    &.icon_sprites-xOrange8 {
        @include icon_sprites-sprites($icon_sprites-xOrange8-left, $icon_sprites-xOrange8-top, 8px, 8px);
    }

    &.icon_sprites-xRed17 {
        @include icon_sprites-sprites($icon_sprites-xRed17-left, $icon_sprites-xRed17-top, 17px, 17px);
    }

    &.icon_sprites-xRed19 {
        @include icon_sprites-sprites($icon_sprites-xRed19-left, $icon_sprites-xRed19-top, 19px, 19px);
    }

    &.icon_sprites-xRed8 {
        @include icon_sprites-sprites($icon_sprites-xRed8-left, $icon_sprites-xRed8-top, 8px, 8px);
    }
}

.icon_sprites.icon_sprites-removeXBlue10.deleteCaseAction {
    background-position: -16px -2259px;
    width: 10px;
    height: 10px;
    background-image: url(Images/Sprites/icon_sprites.png?v=3102016);
}
.icon_sprites.icon_sprites-xGray8 {
    background-image: url(Images/Sprites/icon_sprites.png?v=3102016);
    background-position: -16px -2899px;
    width: 8px;
    height: 8px;
}
.icon_sprites.icon_sprites-trash29 {
    background-image: url(Images/Sprites/icon_sprites.png?v=3102016);
    background-position: -16px -2677px;
    height: 23px !important;
    width: 23px;
}

.icon_sprites.icon_sprites-copy10 {
    background-image: url(Images/Sprites/icon_sprites.png?v=3102016);
    background-position: -16px -619px;
    height: 23px !important;
    width: 23px;
}