@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

$separator-border: #aaa;
$c-centered-overlay-border: #ccc;
$c-column-i-color: #a8a8a8;
$c-column-h3-light-color: #9e9f9f;

.c-top-separator {  border-top: 1px solid $separator-border;  }

.c-bottom-separator {  border-bottom: 1px solid $separator-border;  }



/* ----------------------------------------------------------------- *\
        # Static Pages w/ Modal-contained content
        ## These modals can be used over full-screen
        ## background images

        ## Example: //static.allegiancetech.com/Info/Feedback.html
/* ----------------------------------------------------------------- */


/**
 * The 'centered-overlay-container' along with it's ':before' psuedo
 * selector are responsible for centering all the content
 */
.c-centered-overlay-container {
  text-align: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  position:absolute;
  left: 0;
  right:0;
  top:50%;
  @include transform("translateY", -50%);
}

/**
 * The 'centered-overlay' class is the visible modal container area
 */
.c-centered-overlay {
  @include background-opacity(#e3e3e3, 0.95);
  @include border-radius(2px);
  @include box-shadow(0 1px 1px 0 #9e9e9e);
  color: $color-font-medium;
  text-align:center;
  padding: 10px 20px 40px;

  > .header {
    background: url("../../Login/images/InMoment-logo.svg") center center no-repeat;
    height: 129px;
    border-bottom: $c-centered-overlay-border;
    margin: 0 20px 0;
  }
}

.c-column {
  min-width: 150px;
  @include font();
  text-align: center;
  vertical-align: top;
  overflow: auto;
  position: relative;
  overflow-x: hidden;
  display: inline-block;
  margin-top: 20px;

  > .content {
    margin-bottom: 50px;

    i {
      color: $c-column-i-color;
      font-size: 56px;
    }

    h3.dark {
      color: $color-font-medium;
      text-transform: uppercase;
    }

    h3.light {
      color: $c-column-h3-light-color;
    }

    p.message {
      color: $color-font-medium;
    }
  }

  .buttonContainer {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

/* Used on Maintenance Page  - DEV-11440 */
.c-largeAndLight {
  margin:30px;
}
.c-largeAndLight,
.c-largeAndLight a {
  font-size:30px;
  @include font-light();
  text-transform: inherit;
}

.c-globalLoadFallback {
  color: #fff;
  text-align: center;
  font-size: 72px;
  margin-top: 20%;
}


/* File upload */

.fileUploadArea,
.fileUploadPreview,
.fileUploadProgress {
  display: none;
  width: 480px;
}

.previewArea,
.dropZone {
  height: 60px;
}

.fileUploadArea {
  color: #757575;
  font-size: 14px;
  height: 120px;
  text-align: center;
}

.dropZone {
  border: 1px dashed #CCC;
  line-height: 60px;
  margin: 2px 0 10px;
  vertical-align: middle;
  width: 468px;
  position: relative;

  &.hover {
    border: 1px dashed #90d602;
  }
}


.previewArea {
  background-color: #f1f1f1;
  border: 1px solid #CCC;
  border-left: none;
  border-right: none;
  padding: 2px;
  position:relative;
}

.filePreview {
  display: inline-block;
  text-align:left;
  width: auto;
}

.filePreview > img {
  height: 60px;
}

label.fileName {
  display: inline-block;
  margin: 10px;
}

.fileSize {
  float: right;
  margin: 8px;
  text-align: right;
}

.fileSizeWarning {
  bottom: 26px;
  color: #cf3a28;
  display: none;
  position: relative;
  text-align: center;
}

.fileExtensionWarning {
  bottom: 26px;
  color: #cf3a28;
  display: none;
  position: relative;
  text-align: center;
}

.fileCountWarning {
  color: #cf3a28;
  display: none;
  position: relative;
  text-align: center;
  width: 380px;
  padding-top: 14px;
}

.fileUploadPreview {
  position:relative;

  .uploadSuccess {
    display:none;
    color:green;
    position:absolute;
    top:0;
    bottom:25px;
    left:0;
    right:0;
    background-color: rgba(#000, 0.25);
  }

  .uploadError {
    @extend .uploadSuccess;
    color: red;

  }
  .fileUploadProgress {
    background-color: rgba(#000,0.8);
    height: 28px;
    left: 0;
    padding: 18px 0;
    position: absolute;
    top: 0;

    .progress {
      background-color: #646464;
      border-radius: 2px;
      height: 20px;
      margin: auto;
      padding: 2px;
      width: 260px;

      .bar {
        background-color: #90d602;
        height: 20px;
        width: 0;
        border-radius: 2px;
        @include transition(width, 0.25s);
      }
    }
  }
}

.c-notificationBadge {
  background: $color-background-green-mcx;
  border-radius: 100%;
  color:#000;
  height: 19px;
  left:1px;
  line-height: 19px;
  position:absolute;
  text-align: center;
  top:2px;
  width: 25px;
  z-index: 0;

  .slick-row & {
    left: 60px;
  }
  .o-tabLink & {
    right: -15px;
    left: auto;
  }
  .reportFilterContainer & {
    left: auto;
    right: -30px;
    top: 5px;
  }

  .fixedTokenItem & {
    right: 50px;
    top: 5px;
    left: auto;
  }

  &:before {
    color: $color-font-green-mcx;
    content: attr(data-icon);
    font-family: shared;
    font-size:25px;
    left: 0;
    position: absolute;
    top:2px;
    z-index: -1;
  }
}