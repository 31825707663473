@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* --------------------------------- *\
		# General Formatting
\* --------------------------------- */

.u-p0 { padding: 0 !important; }
.u-pl15 { padding-left: 15px !important; }
.u-pl30 { padding-left: 30px !important; }

.u-show { display:block !important; }
.u-hide { display:none !important; }

.u-inlineBlock { display: inline-block !important; }
.u-block { display: block !important; }

.u-m0 { margin: 0; }
.u-m5 { margin: 5px; }
.u-m10 { margin: 10px; }
.u-m15 { margin: 15px; }
.u-m30 { margin: 30px; }

.u-mb5 { margin-bottom: 5px; }
.u-mb10 { margin-bottom: 10px; }
.u-mb15 { margin-bottom: 15px; }
.u-mb20 { margin-bottom: 20px !important; }
.u-mb30 { margin-bottom: 30px !important; }

.u-indent20 { margin-left: 20px; }
.u-indent30 { margin-left: 30px; }
.u-indent35 { margin-left: 35px !important; }

.u-width50pct {
    width:50%;
    max-width:50%;
}

.u-overflowAuto {
  overflow:auto;
}

/* --------------------------------- *\
		# Text Formatting
\* --------------------------------- */
.u-bold {
    font-family: 'ProximaNova-Semibold', arial, helvetica, sans-serif !important;
    font-weight: 400 !important;
}
.u-regular {
    font-family: 'ProximaNova-Regular', arial, helvetica, sans-serif !important;
    font-weight: 400 !important;
}

/* --------------------------------- *\
		# Floats (ala Bootstrap)
\* --------------------------------- */

.u-pull-left {
    float: left;
}

.u-pull-right {
    float: right;
}

.u-pull-center {
    margin:0 auto;
}



/* --------------------------------- *\
		# Pseudo Columns
\* --------------------------------- */

/**
 * Used with one column
 *
 * DEVELOPER'S NOTE: Might not be useful. Remove if that's the case.
 */
.u-column-full {
    width: 100%;
}

/**
 * Used with 2 column layout
 */
.u-column-half {
    width: 50%;
}

/**
 * Used with 3 column layout
 */
.u-column-third {
    width: 30.33%;
}

/**
 * Used with 4 column layout
 */
.u-column-quarter {
    width: 24%;
}


/* --------------------------------- *\
		# States
\* --------------------------------- */
.is-disabled {
  color: #333;
  filter: alpha(opacity=50);
  opacity: .5;
}

/* --------------------------------- *\
		# Animations
\* --------------------------------- */
.u-fadeOut {
    @include opacity(0);
    visibility:hidden;
    @include transition(opacity 0.25s);
}

.u-transparent {
    @include opacity(0);
}

.u-fadeIn {
     @include opacity(1);
     visibility:visible;
     @include transition(opacity 0.25s !important);
 }

$durations: (250, 500, 1000);
@each $type in $durations {
  .u-fadeIn#{nth($type, 1)} {
    @include opacity(1);
    visibility:visible;
    @include transition(opacity #{$type/1000}s !important);
  }
}
