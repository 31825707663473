input[type=text].t-dark {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 25px;
    color: #253746;
}

a.t-dark {
    color:#499400;
    text-decoration:none;
}
a.t-dark:hover {
    color: #8ac452;
    cursor:pointer;
    text-decoration:none;
}