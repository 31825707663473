@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';
@import 'spritePositions';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
						/* Slosh Bucket Slim */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.sloshBucketSlim {
	font-size: 11px;

	> div {
		display: inline-block;
		vertical-align: top;

		&.sloshBucketSlimFrom {
			width: 218px;
			margin: 10px;
		}

		&.sloshBucketSlimTo {
			width: 200px;
			margin: 10px;
		}
	}

	div {
		&.allegRedline {
			margin-bottom: 5px;
			font-size: 11px;
		}

		&.allegSearchWrapper {
			padding: 0;
			padding-right: 52px;
			margin: 0;
			display: block;

			.allegSearch{
				margin: 0 0 5px 0;
			}

			.allegSearchClear{
				top: 7px;
				right: 5px;
			}
		}
	}

	.sloshBucketSlimFromContainer {
		border: 1px solid #aaa;
		border-bottom-width: 0;

		.sloshBucketSlimGroup {
			position: relative;
			cursor: default;

			> div.groupTitle {
				border-bottom: 1px solid #aaa;
				height: 23px;
				line-height: 23px;
				background-color: #dbdbdb;
				padding-left: 14px;
				padding-right: 9px;
				font-weight: bold;

				&:before
				{
					position: absolute;
					left: 5px;
					top: 8px;
					width: 7px;
					height: 7px;
					content: ' ';
					background-position: $icon_sprites-arrowRightGray7-left $icon_sprites-arrowRightGray7-top;
					background-image: url(Images/Sprites/icon_sprites.png);
				}

				.groupName {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			&.expanded > div.groupTitle:before
			{
				background-position: $icon_sprites-arrowDownGray7-left $icon_sprites-arrowDownGray7-top;
			}

			div.groupContainer {
				display: none;
				height: 287px;
				overflow-y: scroll !important;
				border-bottom: 1px solid #aaa;

				> ul.sloshBucketSlimGroupContainer
				{
					border-right: 1px solid #aaa;
					min-height: 100%;
					overflow: hidden;
					margin-bottom: -1px;
				}

				> ul > li.sloshBucketSlimItem
				{
					height: 23px;
					line-height: 23px;
					padding-left: 9px;
					padding-right: 16px;
					position: relative;
					border-bottom: 1px solid #aaa;
					cursor: move;

					.sloshBucketSlimItemAction {
						position: absolute;
						right: 2px;
						top: 6px;
						width: 12px;
						height: 12px;
						content: ' ';
						cursor: pointer;
						background-position: $icon_sprites-addGray12-left $icon_sprites-addGray12-top;
						background-image: url(Images/Sprites/icon_sprites.png);
					}

					&:hover .sloshBucketSlimItemAction {
						background-position: $icon_sprites-addBlue12-left $icon_sprites-addBlue12-top;
					}

					&.selected,
					&.filtered {
						display: none;
					}

					.sloshBucketSlimItemName {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}
			}

			&.expanded div.groupContainer {
				display: block;
			}

		}

		.sloshBucketSlimEmptyGroup
		{
			display: none;
		}
	}

	ul {
		list-style: none;

		> li.sloshBucketSlimItem {
			box-sizing: border-box;

			&.ui-draggable-dragging,
			&.ui-sortable-helper {
				border: 1px solid #aaa;
				background-color: #fff;
			}

			&:before
			{
				position: absolute;
				left: 2px;
				top: 5px;
				width: 3px;
				height: 13px;
				content: ' ';
				background-position: $icon_sprites-dragDots15-left $icon_sprites-dragDots15-top;
				background-image: url(Images/Sprites/icon_sprites.png);
			}
		}

		> li.sloshBucketSlimGroup.ui-sortable-helper
		{
			border-top: 1px solid #aaa;
			border-bottom: 1px solid #aaa !important;
		}

		> li.sloshBucketSlimItem.ui-sortable-placeholder,
		> li.sloshBucketSlimGroup.ui-sortable-placeholder
		{
			visibility: visible !important;
			background-color: #fff;
			list-style: none;

			&before,
			&:after {
				content: none !important;
			}
		}
	}

	.addMultiSourceColumn {
		text-align: right;
		font-size: 11px;
		color: #336699;
		cursor: pointer;
	}

	.sloshBucketSlimToContainer {

		.sloshBucketSlimItemsWrapper {
			border: 1px solid #aaa;
			position: relative;
		}

		.sloshBucketSlimItemContainer{
			min-height: 23px;
		}

		.groupContainer {
			background-color: #fff;
			position: relative;
		}

		.sloshBucketSlimEmptyGroup {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 21px;
			line-height: 21px;
			text-align: center;
			border: 1px dashed #aaa;
		}

		ul.sloshBucketSlimGroupContainer {
			min-height: 23px;
		}

		li.sloshBucketSlimItem {
			height: 23px;
			line-height: 23px;
			border-bottom: 1px solid #aaa;
			padding-left: 9px;
			padding-right: 19px;
			position: relative;
			cursor: move;
			background-color: #fff;

			.sloshBucketSlimItemName
			{
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.sloshBucketSlimItemEdit input {
				box-sizing: border-box;
				height: 19px;
				line-height: 19px;
				font-size: 11px;
			}
		}

		ul > li.sloshBucketSlimItem .sloshBucketSlimItemAction,
		li.sloshBucketSlimGroup div.groupTitle .sloshBucketSlimGroupAction
		{
			position: absolute;
			right: 3px;
			top: 5px;
			width: 13px;
			height: 13px;
			content: ' ';
			cursor: pointer;
			background-position: $icon_sprites-removeGray13-left $icon_sprites-removeGray13-top;
			background-image: url(Images/Sprites/icon_sprites.png);
		}

		ul > li.sloshBucketSlimItem:hover .sloshBucketSlimItemAction,
		li.sloshBucketSlimGroup div.groupTitle:hover .sloshBucketSlimGroupAction
		{
			background-position: $icon_sprites-removeBlue13-left $icon_sprites-removeBlue13-top;
		}

		li.sloshBucketSlimGroup
		{
			padding: 0 0 0 6px;
			cursor: default;
			background-color: #ffcc00;
			border-bottom: 1px solid #aaa;
		}

		li.sloshBucketSlimGroup div.groupTitle {
			background-color: #fffbeb;
			padding-left: 4px;
			padding-right: 19px;
			height: 23px;
			line-height: 23px;
			position: relative;
			cursor: move;
		}

		li.sloshBucketSlimGroup div.groupTitle .groupName {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		li.sloshBucketSlimGroup div.groupTitle .groupNameEdit input
		{
			box-sizing: border-box;
			height: 19px;
			line-height: 19px;
			font-size: 11px;
		}

		ul li:last-child{
			border-bottom: 0;
		}

		ul li.sloshBucketSlimGroup ul li{
			border-left: 1px solid #aaa;
		}

		ul li.sloshBucketSlimGroup ul li:first-child{
			border-top: 1px solid #aaa;
		}
	}
}


