@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Table */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

body.t-mcx .o-mcx-table.small,
.o-mcx-table {
	border-collapse: collapse;
	font-size: 12px;
	text-align: left;
	color: #253746;
	width: 100%;
}

.o-mcx-table.small 
{
	font-size:10.67px;
}

.o-mcx-table th {
	background-color: #e5e5e5;
	font-weight: 700;
}

body.t-mcx .o-mcx-table.small th,
body.t-mcx .o-mcx-table.small td,
.o-mcx-table th,
.o-mcx-table td {
	padding:3px 6px;
	border:0;
	height:24px;
	border-left:1px solid #d7d7d7;
	border-bottom:1px solid #d7d7d7;
}

.o-mcx-table.small th,
.o-mcx-table.small td
{
   padding:0 10px;
   height:19px;
}

.o-mcx-table.small td 
{
	font-size:10px;
	height:17px;
}

.o-mcx-table.noLeftBorder td,
.o-mcx-table.noLeftBorder th:not(:first-child)
{
	border-left:0;
}
.o-mcx-table.noBottomBorder td,
.o-mcx-table.noBottomBorder th
{
	border-bottom:0;
}
.o-mcx-table.noBorder td,
.o-mcx-table.noBorder th  
{
	border:0;
}

/*.o-mcx-table.topBorderOnly td,*/
/*.o-mcx-table.topBorderOnly th */
/*{*/
	/*border:0;*/
	/*border-top:1px solid #ccc;*/
/*}*/
body.t-mcx .o-mcx-table tr th:first-child,
body.t-mcx .o-mcx-table tr td:first-child {
	padding-left: 15px;
}

.o-mcx-table tr:nth-child(odd) {
	background-color:#fff;
}

.o-mcx-table tr:nth-child(even) {
	background-color:#f9f9f9;
}
body.t-mcx .o-mcx-table tr:hover,
body.t-mcx .o-mcx-table tr:nth-child(odd):hover,
body.t-mcx .o-mcx-table tr:nth-child(even):hover {
	background-color: #EBEBEB
}

.o-mcx-table .tableIcons 
{
	opacity:0.3;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
	filter: alpha(opacity=30);
	float:right;
	-webkit-transition: opacity .15s ease-in-out;
	-moz-transition: opacity .15s ease-in-out;
	-ms-transition: opacity .15s ease-in-out;
	-o-transition: opacity .15s ease-in-out;
	transition: opacity .15s ease-in-out;
}

.o-mcx-table tr:hover .tableIcons 
{
	opacity:1;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	filter: alpha(opacity=100);
}

.o-mcx-table tr .tableIcons .disabled
{
	visibility:hidden;
	cursor:default;
}
.o-mcx-table .tableIcons .tableMenu 
{  
	padding:3px;
	position:relative;
}

.o-mcx-table .tableMenu:hover
{
	cursor:pointer;
	background:#eaeaea;
}
.o-mcx-table .tableMenu:hover ul 
{
	border:1px solid #ccc;
	border-bottom-width:2px;
	border-left-width:2px;
	z-index: z('elevated');
	position:absolute;
	background:#fff;
	display:block !important;
}

.o-mcx-table .tableMenu .allegMenuItems
{
    display: none;
}

.o-mcx-table .tableMenu:hover ul li a
{
	padding:5px;
	min-width:150px;
}

.o-mcx-table tr .tableIcons.grayOut div
{
	background-position-x: -32px;
}


.o-mcx-table .tableIcons > *
{
	display:inline-block;
	margin-left:5px;
}

/*.o-mcx-tableHeader */
/*{*/
	/*background:#e5e5e5;*/
	/*height:31px;*/
	/*border:1px solid #d7d7d7;*/
	/*border-bottom:0;*/
/*}*/

/*.o-mcx-tableFixedHeaderWrapper */
/*{*/
	/*overflow-y:auto;*/
	/*border:1px solid #d7d7d7;*/
/*}*/
/*.o-mcx-tableFixedHeaderContainer .o-mcx-table tr th*/
/*{*/
	/*background-color:transparent;*/
	/*height:0;*/
	/*padding:0 !important;*/
	/*border:0;*/
/*}*/

/*.o-mcx-tableFixedHeaderContainer .o-mcx-table */
/*{*/
	/*border-top:0;*/
	/*border-bottom:0;*/
	/*border-left:0;*/
/*}*/
/*.o-mcx-tableFixedHeaderContainer */
/*{*/
	/*position:relative;*/
/*}*/
/*.o-mcx-tableFixedHeaderContainer .o-mcx-table tr th:first-child > div */
/*{*/
	/*border:0;*/
/*}*/
/*.o-mcx-tableFixedHeaderContainer .o-mcx-table tr th > div */
/*{*/
	/*position:absolute;*/
	/*top:0;*/
	/*border-left: 1px solid #d7d7d7;*/
	/*line-height:31px;*/
	/*padding-left:9px;*/
/*}*/
/*.o-mcx-tableFixedHeaderContainer .o-mcx-table tr td:first-child*/
/*{*/
	/*border-left:0;*/
/*}*/
/*.o-mcx-tableFixedHeaderContainer .o-mcx-table tr:last-child td */
/*{*/
	/*border-bottom:0;*/
/*}*/


/*tempUi*/

body.t-mcx .o-mcx-table {
	border-collapse:collapse;
    border: 0;
	font-size:14px;
	text-align:left;
	color:#404040;
	width:100%;
}

body.t-mcx .o-mcx-table.small
{
	font-size:10.67px;
}

body.t-mcx .o-mcx-table th
{
	font-size: 14px;
	color: #404040;
}
body.t-mcx .o-mcx-table.c-limitedSpace th {
	padding: 3px 6px;
}

body.t-mcx .o-mcx-table th,
body.t-mcx .o-mcx-table td {
	/*padding:3px 40px 3px 10px;*/
	border:0;
	min-width: 32px;
    box-sizing: border-box;
	height:38px;
}
body.t-mcx .o-mcx-table tr {
    transition: all 0.15s;   
}
body.t-mcx .o-mcx-table thead tr:hover {
    color: #404040;
}

body.t-mcx .o-mcx-table th {
	background-color: #d2d2d2;
    font-family: ProximaNova-Semibold, arial, helvetica, sans-serif;
	font-weight: normal;
	padding-top:18px;
}

body.t-mcx .o-mcx-table.small th,
body.t-mcx .o-mcx-table.small td
{
   padding:0 10px;
   height:19px;
}

body.t-mcx .o-mcx-table.small td
{
	font-size:10px;
	height:17px;
}

body.t-mcx .o-mcx-table.noLeftBorder td,
body.t-mcx .o-mcx-table.noLeftBorder th:not(:first-child)
{
	border-left:0;
}
body.t-mcx .o-mcx-table.noBottomBorder td,
body.t-mcx .o-mcx-table.noBottomBorder th
{
	border-bottom:0;
}
body.t-mcx .o-mcx-table.noBorder td,
body.t-mcx .o-mcx-table.noBorder th
{
	border:0;
}

body.t-mcx .o-mcx-table.topBorderOnly td,
body.t-mcx .o-mcx-table.topBorderOnly th
{
	border:0;
	border-top:1px solid #ccc;
}
body.t-mcx .o-mcx-tableFixedHeaderContainer
{
	position: relative;
}
body.t-mcx .o-mcx-table
{
	position: relative;
}
body.t-mcx .o-mcx-tableFixedHeaderContainer .o-mcx-table
{
	position: inherit;
}
body.t-mcx .o-mcx-table tbody:empty:after,
body.t-mcx .grid.o-mcx-table .o-mcx-table-rows:empty:after
{
	content: attr(data-emptymessage);
	font-size: 22px;
	color: #b1b1b1;
	position: absolute;
    top: 50px;
    left: 12px;
    right: 12px;
	white-space: pre-line;
}
body.t-mcx .grid.o-mcx-table .o-mcx-table-rows:empty:after
{
	top: 11px;
}
body.t-mcx .o-mcx-table tr:nth-child(odd),
body.t-mcx .o-mcx-table .o-mcx-table-row.odd
{
	background-color:#fff;
}

body.t-mcx .o-mcx-table tr:nth-child(even),
body.t-mcx .o-mcx-table .o-mcx-table-row.even
{
	background-color:#f7f7f7;
}

body.t-mcx .o-mcx-table .tableIcons
{
    height: 100%;
	opacity:0;
    display: table;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=00);
	filter: alpha(opacity=0);
	float:right;
	-webkit-transition: opacity .15s ease-in-out;
	-moz-transition: opacity .15s ease-in-out;
	-ms-transition: opacity .15s ease-in-out;
	-o-transition: opacity .15s ease-in-out;
	transition: opacity .15s ease-in-out;
}

body.t-mcx .o-mcx-table tr:hover .tableIcons,
body.t-mcx .o-mcx-table .o-mcx-table-row:hover .tableIcons
{
	opacity:1;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	filter: alpha(opacity=100);
}

body.t-mcx .o-mcx-table tr .tableIcons .disabled,
body.t-mcx .o-mcx-table .o-mcx-table-row .tableIcons .disabled
{
	visibility:hidden;
	cursor:default;
}
body.t-mcx .o-mcx-table .tableIcons .tableMenu
{  
	padding:3px;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
	position:relative;
}

body.t-mcx .o-mcx-table tr .tableIcons .tableMenu > div,
body.t-mcx .o-mcx-table .o-mcx-table-row .tableIcons .tableMenu > div
{
	position: absolute;
    left: 15px;
	top: 12px;
    transition: all 0.35s;
}

body.t-mcx .o-mcx-table tr:hover .tableIcons .tableMenu > div,
body.t-mcx .o-mcx-table .o-mcx-table-row:hover .tableIcons .tableMenu > div
{
    left: 8px;
}

body.t-mcx .o-mcx-table .tableMenu:hover
{
	cursor:pointer;
	background:#eaeaea;
}
body.t-mcx .o-mcx-table .tableMenu:hover ul
{
    border-radius: 3px;
    border-top-left-radius: 0;
    border: 0;
	z-index: z('elevated');
	position:absolute;
	background:#54595c;
    min-width: 160px;
    max-width: 190px;
    left: -1px;
    top: 28px;
	display:block !important;
}

body.t-mcx .o-mcx-table .tableMenu.openTop:hover ul
{
    border-radius: 3px;
    border-bottom-left-radius: 0;
    top: auto;
    bottom: 38px;
}

body.t-mcx .o-mcx-table .tableMenu .allegMenuItems
{
    display: none;
}
body.t-mcx .o-mcx-table .tableMenu:hover ul li a
{
	padding:5px 10px;
	min-width:0;
    color: #fff;
    transition: all 0.15s;
}
body.t-mcx .o-mcx-table .tableMenu ul li a.disabledMenuItem
{
	color: #808080;
	cursor: default;
	background: initial !important;
	padding-left: 10px !important;
}

body.t-mcx .allegDropdownMenu:hover ul li a:before,
body.t-mcx .allegMenuItems li a:before,
body.t-mcx .allegMenuItems li:hover a:before {
    content: '';
}

body.t-mcx .o-mcx-table .tableMenu:hover ul li a:hover
{
    background: #494d4f;
    padding-left: 15px;
	padding-right: 5px;
}

body.t-mcx .o-mcx-table tr .tableIcons.grayOut div,
body.t-mcx .o-mcx-table .o-mcx-table-row .tableIcons.grayOut div
{
	background-position-x: -16px;
}

body.t-mcx .o-mcx-table tr .tableIcons:hover div,
body.t-mcx .o-mcx-table .o-mcx-table-row .tableIcons:hover div
{
    background-color: #54595c;
}


body.t-mcx .o-mcx-table .tableIcons > *
{
	display:inline-block;
	margin-left:0;
}

body.t-mcx .o-mcx-tableHeader
{
    box-sizing: border-box;
	background:#d2d2d2;
	height:39px;
    border: 0;
}
body.t-mcx .o-mcx-tableFixedHeaderWrapper
{
	overflow-y:auto;
	border:0;
}
body.t-mcx .o-mcx-tableFixedHeaderContainer .o-mcx-table tr th
{
	background-color:transparent;
	height:0;
	padding:0 !important;
	border:0;
}

body.t-mcx .o-mcx-tableFixedHeaderContainer .o-mcx-table
{
	border-top:0;
	border-bottom:0;
	border-left:0;
}
body.t-mcx .o-mcx-tableFixedHeaderContainer
{
	position:relative;
}
body.t-mcx .o-mcx-tableFixedHeaderContainer .o-mcx-table tr th:first-child > div
{
	border:0;
}
body.t-mcx .o-mcx-tableFixedHeaderContainer .o-mcx-table tr th > div
{
	position:absolute;
	top:13px;
    border-left: 0;
	line-height:31px;
	padding-left:9px;
}
body.t-mcx .o-mcx-tableFixedHeaderContainer .o-mcx-table tr td:first-child
{
	border-left:0;
}
body.t-mcx .o-mcx-tableFixedHeaderContainer .o-mcx-table tr:last-child td
{
	border-bottom:0;
}

body.t-mcx .grid.o-mcx-table,
.reportTableContent .grid.o-mcx-table
{
    border: 0;
	font-size:14px;
	text-align:left;
	color:#404040;
	width:100%;
}
body.t-mcx .grid.o-mcx-table .tableMenu:hover ul,
.reportTableContent .grid.o-mcx-table .tableMenu:hover ul
{
	left: 0;
}

body.t-mcx .grid.o-mcx-table .grid-canvas,
.reportTableContent .grid.o-mcx-table .grid-canvas
{
	width: 100% !important;
}

body.t-mcx .o-mcx-sort-indicator,
.reportTableContent .o-mcx-sort-indicator
{
	position: relative;
	margin: 0;
	vertical-align: middle;
	top: 0;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row
{
	height:38px;
    transition: all 0.15s;
	border: 0;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row.o-mcx-group,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row.o-mcx-group
{
	height: 8px;
	background-color: #d2d2d2 !important;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row.o-mcx-group:first-child,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row.o-mcx-group:first-child
{
	display: none;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell:first-child:before,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell:first-child:before
{
	content: '\00a0\00a0\00a0';
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column
{
	line-height: normal; /* use normal for IE since initial is not supported */
	overflow: visible;
	font-size: 0;
	margin-left: 0;
    width: 40px;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column:before,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column:before
{
	content: '';
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column .tableIcons,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column .tableIcons
{
	width: 32px;
	font-size: 12px;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column .dragThumb,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column .dragThumb
{
	border: 1px solid #ccc;
	border-radius: 2px;
	box-sizing: border-box;
	width: 8px;
	height: 26px;
	background-color: #ebebeb;
	margin: 5px 7px;
	padding-left: 1px;
	padding-top: 6px;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column .dragThumb .icon_sprites,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column .dragThumb .icon_sprites
{
	cursor: move;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column > div,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row .o-mcx-table-cell.o-menu-column > div
{
	display: inline-block;
	vertical-align: middle;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row:hover,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row:hover
{
	background-color: #ebebeb !important;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-row.o-mcx-group:hover,
.reportTableContent .grid.o-mcx-table .o-mcx-table-row.o-mcx-group:hover
{
	background-color: #d2d2d2 !important;
}

body.t-mcx .grid.o-mcx-table.o-mcx-tableReorder .o-mcx-table-row,
body.t-mcx .grid.o-mcx-table.o-mcx-tableReorder .o-mcx-table-cell,
.reportTableContent .grid.o-mcx-table.o-mcx-tableReorder .o-mcx-table-row,
.reportTableContent .grid.o-mcx-table.o-mcx-tableReorder .o-mcx-table-cell
{
	cursor: move;
}

body.t-mcx .grid.o-mcx-table.o-mcx-tableReorder .o-mcx-table-row.o-mcx-group,
body.t-mcx .grid.o-mcx-table.o-mcx-tableReorder .o-mcx-group .o-mcx-table-cell,
.reportTableContent .grid.o-mcx-table.o-mcx-tableReorder .o-mcx-table-row.o-mcx-group,
.reportTableContent .grid.o-mcx-table.o-mcx-tableReorder .o-mcx-group .o-mcx-table-cell
{
	cursor: default;
}

body.t-mcx .grid.o-mcx-table .o-mcx-header,
.reportTableContent .grid.o-mcx-table .o-mcx-header
{
	font-size: 14px;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-cell,
.reportTableContent .grid.o-mcx-table .o-mcx-table-cell
{
	border:0;
	box-sizing: border-box;
	padding: 0;
	padding-left:5px;
	z-index: z('initial');
	line-height: 38px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-cell > div
{
    padding: 0;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-cell > .cellContent,
.reportTableContent .grid.o-mcx-table .o-mcx-table-cell > .cellContent
{
	margin-right: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-cell .tableIcons,
.reportTableContent .grid.o-mcx-table .o-mcx-table-cell .tableIcons
{
	height: 38px;
	border-collapse:collapse;
	width: 100%;
}

body.t-mcx .grid.o-mcx-table .o-mcx-table-cell.selected,
.reportTableContent .grid.o-mcx-table .o-mcx-table-cell.selected
{
	background-color: transparent;
}

body.t-mcx .grid.o-mcx-table .o-mcx-header,
.reportTableContent .grid.o-mcx-table .o-mcx-header
{
	background-color: #d2d2d2;
	border: 0;
}

body.t-mcx .grid.o-mcx-table .o-mcx-header .o-mcx-header-columns,
.reportTableContent .grid.o-mcx-table .o-mcx-header .o-mcx-header-columns
{
	background-color: transparent;
}

body.t-mcx .grid.o-mcx-table .o-mcx-header .o-mcx-header-columns .o-mcx-header-column:first-child:before,
.reportTableContent .grid.o-mcx-table .o-mcx-header .o-mcx-header-columns .o-mcx-header-column:first-child:before
{
	content: '\00a0\00a0\00a0';
}

body.t-mcx .grid.o-mcx-table .o-mcx-header .o-mcx-header-columns .o-mcx-header-column.o-menu-column,
.reportTableContent .grid.o-mcx-table .o-mcx-header .o-mcx-header-columns .o-mcx-header-column.o-menu-column
{
	padding: 0;
	margin-left: 0;
}

body.t-mcx .grid.o-mcx-table .o-mcx-header .o-mcx-header-columns .o-mcx-header-column.o-menu-column:before,
.reportTableContent .grid.o-mcx-table .o-mcx-header .o-mcx-header-columns .o-mcx-header-column.o-menu-column:before
{
	content: '';
}

body.t-mcx .grid.o-mcx-table .o-mcx-header .o-mcx-header-column,
.reportTableContent .grid.o-mcx-table .o-mcx-header .o-mcx-header-column
{
	border:0;
	white-space: nowrap;
	box-sizing: border-box;
	height:38px;
	z-index: z('initial');
	background-color: #d2d2d2;
	font-weight: normal;
    font-family: ProximaNova-Semibold, arial, helvetica, sans-serif;
	color: #404040;
	padding: 18px 0 0 0;
}

.o-mcx-column-name {
	padding-left:5px;
}
.reportTableContent .grid.o-mcx-table .o-mcx-header .o-mcx-header-column.ui-draggable
{
	cursor: move;
}

body.t-mcx .grid.o-mcx-table .o-mcx-header-column.ui-sortable-handle.ui-state-hover,
.reportTableContent .grid.o-mcx-table .o-mcx-header-column.ui-sortable-handle.ui-state-hover
{
    background-color: #e0e0e0;
    cursor: move;
}

body.t-mcx .grid.o-mcx-table .o-mcx-header-column.ui-sortable-handle.ui-state-hover.o-mcx-header-column-active,
.reportTableContent .grid.o-mcx-table .o-mcx-header-column.ui-sortable-handle.ui-state-hover.o-mcx-header-column-active
{
    background-color: #8ac452;
    opacity: 0.5;
}

/*Stupid firefox hack, remove this when body.t-mcx is removed*/
.CaseProgramHistoryRegion .o-mcx-header-column,
.caseProgramGrid .o-mcx-header-column,
.AlertSetupRegion .o-mcx-header-column,
.emailTemplatesGrid .o-mcx-header-column,
.caseInboxGrid .o-mcx-header-column
{
	box-sizing: border-box;
}

/**
 * These are overrides for .MainListTable
 */
.MainListTable {
	border-right: none;
	border-left: none;
	border-top: none;
	border-bottom: none;
}
.MainListTable th {
	border-bottom: none;
	border-right: none;
}
.MainListTable td {
 	border-bottom: none;
 	border-right: none;
}

/**
 * Overrides for the Telerik RadGrid which isn't really rad at all.
 * Talk about a blackbox.
 */
body .GridHeader_Default {
	border: none;
}
body .GridRow_Default td,
body .GridAltRow_Default td {
	border: none;
}

body .GridRow_Default {
	background-color: #FFFFFF;
}
body .GridAltRow_Default {
	background-color: #F7f7f7;
}

body .GridHeader_Default:first-child,
body .GridRow_Default>td:first-child,
body .GridAltRow_Default>td:first-child,
body .GridFooter_Default>td:first-child,
body .GridGroupFooter_Default>td:first-child {
	padding-left: 15px;
}

body .GridHeader_Default,
body .GridHeader_Default a {
	font-family: "ProximaNova-Semibold", arial, helvetica, sans-serif;
}
body .GridRow_Default td,
body .GridAltRow_Default td,
body .GridEditRow_Default td,
body .GridFooter_Default td,
body .GridGroupFooter_Default td {
	font-family: "ProximaNova-Regular", arial, helvetica, sans-serif;
}

body .GridPager_Default>td {
	padding: 0px 15px 0px;
}