@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

.allegTopTabBar
{
	height: 33px;
	position: absolute;
	left: 280px;
	top: 0;
	min-width:1095px;
}

.allegTopTabs {
	display:inline-block;
	list-style: none;
	font-size: 0;
    padding: 0;

	li:hover a,
	li a.ftSelected
	{
		color: #fff !important;
		background-color: #54595c;
	}

	li {
		display: inline-block;

		a {
			display: block;
			padding: 0 25px;
			text-decoration: none;
			font-size: 13px;
			line-height: 33px;
			display: block;
			color: #cdcdcd !important;
		}

	}
}

