@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Redline Header */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.allegRedline {
	font-size: 12px;
	@include font-regular();
	min-height: 15px;
	margin-bottom: 21px;
	position: relative;
	cursor: default;

	&.extraMargin {
		margin-bottom: 26px;

		&.h1 {
			margin-bottom: 30px;
		}
		&.h2
		{
			margin-bottom:25px;
		}
	}
}

.allegRedline :first-child,
.allegRedline .allegRedlineTitle { /* ??? */
	padding-right: 5px;
	position: absolute;
	font-weight: 700;
}

h1.allegRedline,
.allegRedline.h1 {
	font-size:24px;
	min-height:30px;
	margin-bottom:25px;
}

#divConfigureSection .allegRedline.h2 {
	margin-bottom:10px;
}

h2.allegRedline,
.allegRedline.h2 {
	font-size:16px;
	min-height:20px;
	margin-bottom:15px;
}

h2.allegRedline :last-child,
.allegRedline.h2 :last-child {
	margin-top: 10px;
}

h1.allegRedline :last-child,
.allegRedline.h1 :last-child {
	margin-top: 15px;
}

h1,
h1.allegHeader {
    font-size: 18px;
    margin: 15px 0;
}

/* casemanagement ui */
body.t-mcx h1,
body.t-mcx h1.allegHeader {
    @include font-semibold();
}