////
/// @group shared
/// @access public
/// @type variable
////

/// @prop {number} message [9999999] - Highest z-index, nothing should ever be above a message (these are usually browser/platform notifications)
/// @prop {number} message-overlay [9999998] - Overlay for message
/// @prop {number} tutorial-modal [9999997] - Tutorial models are almost above everything
/// @prop {number} tutorial-arrow [9999996] - Arrow for the tutorial
/// @prop {number} tutorial-overlay [9999995] - Overlay for the tutorial modal
/// @prop {number} header-dropdown [99999] - Header dropdowns should be above everything except for modals
/// @prop {number} notification [99999] - Same priority as header.
/// @prop {number} chart-options [99999] - Chart options.
/// @prop {number} tooltip [10001] - Tooltips should exist above most items.
/// @prop {number} filterCriteriaBuilder [10001] - Unique, do not use
/// @prop {number} tpl-select [10000] - Unique, do not use
/// @prop {number} sourceDropOverlay [10000] - Unique, do not use
/// @prop {number} dropdown [9999]
/// @prop {number} reporting-filter-dialog [3002]
/// @prop {number} dialog [3002]
/// @prop {number} sign-out-dialog [101] - Unique, do not use
/// @prop {number} loader [100]
/// @prop {number} filter-form-overlay [99]
/// @prop {number} partial-data [99]
/// @prop {number} filter-menu [99]
/// @prop {number} infobox-tooltip [99]
/// @prop {number} reporting-filter [91]
/// @prop {number} dataAtriibuteMenuArrow [88]
/// @prop {number} filter-form [33]
/// @prop {number} color-palette [10] - Unique, do not use
/// @prop {number} toolboxExpander [10] - Unique, do not use
/// @prop {number} filterPanelRegion [10] - Unique, do not use
/// @prop {number} token-menu [9] - Unique, do not use
/// @prop {number} range-grip [8] - Unique, do not use
/// @prop {number} showfilter [5] - Unique, do not use
/// @prop {number} toolboxRegion [5] - Unique, do not use
/// @prop {number} chartMetricFilterOverlay [5] - Unique, do not use
/// @prop {number} elevated [2] - One step above most elements
/// @prop {number} default [1] - Force item to have a default z-index
/// @prop {number} zero [0]
/// @prop {} initial [auto]
/// @prop {number} behind [-1]
/// @todo  Investigate if tpl-select and tooltip can be merged.
/// @todo  Investigate if range-grip is needed or if elevated will suffice.
/// @todo  Once all other z-index are accounted for we can use more reasonable numbers
$z-layers: (
  'message':              9999999,
  'message-overlay':      9999998,
  'tutorial-modal':       9999997,
  'tutorial-arrow':       9999996,
  'tutorial-overlay':     9999995,
  'header-dropdown':        99999,
  'notification':           99999,
  'chart-options':          99999,
  'tooltip':                10001,
  'filterCriteriaBuilder':  10001,
  'tpl-select':             10000,
  'sourceDropOverlay':      10000,
  'dropdown':                9999,
  'above-dialog-overlay':    3003,
  'reporting-filter-dialog': 3002,
  'dialog':                  3002,
  'dialog-overlay':          3001,
  'above-jquery':            1000,  /*Jquery UI uses 999, so we need to go higher than that*/
  'btn-footer':               101,
  'sign-out-dialog':          101,
  'date-filter':              101,
  'loader':                   100,
  'hr-page-break':            100,
  'filter-form-overlay':       99,
  'partial-data':              99,
  'filter-menu':               99,
  'infobox-tooltip':           99,
  'reporting-filter':          91,
  'dataAtriibuteMenuArrow':    88,
  'filter-form':               33,
  'color-palette':             10,
  'toolboxExpander':           10,
  'filterPanelRegion':         10,
  'token-menu':                 9,
  'range-grip':                 8,
  'showfilter':                 5,
  'toolboxRegion':              5,
  'chartMetricFilterOverlay':   5,
  'elevated':                   2,
  'default':                    1,
  'zero':                       0,
  'initial':                 auto,
  'behind':                    -1,
);


/// The base font-size for the CSS. All ems are scaled off this by default.
/// @access private
$base-font-size: 13px;