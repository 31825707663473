@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/*WIZARD STEPS/ARROWS */
/**
 * No, these are not the steps Gandalf takes
 * to cast spells. Good try.
 */
body #wizardNavContainer,
#wizardNavContainer {
    margin: 5px 0 10px 0;
	overflow: hidden;
	background-color:#e1e1e1;
	@include border-radius(3px);
	border:1px solid #ccc;
}
body DIV.settingsNavItem,
DIV.settingsNavItem {
	   float: left;
	   padding: 0 10px 0 10px;
	   background-color:#aaa;
	   height:26px;
	   line-height:26px;
	   color:#fff;
	   font-size:15px;
	   cursor: pointer;
}
body DIV.settingsNavItem.active,
body DIV.stepsNavItem.active,
DIV.settingsNavItem.active,
DIV.stepsNavItem.active { 
	background-color: #8ac452;
}
body .settingsNavItem a.colorArrow:after,
.settingsNavItem a.colorArrow:after {
	border-color: transparent transparent transparent #aaa;
	border-style: solid;
	border-width: 13px 0 14px 14px;
	content: "";
	float: left;
	height: 0;
	position: absolute;
	right: -24px;
	top: 0;
	width: 0;
}
body .settingsNavItem a.colorArrow:before,
.settingsNavItem a.colorArrow:before {
	border-color: transparent transparent transparent #fff;
	border-style: solid;
	border-width: 13px 0 14px 14px;
	content: "";
	float: left;
	height: 0;
	position: absolute;
	right: -26px;
	top: 0;
	width: 0;
}
body .settingsNavItem.active a.colorArrow:after,
body .stepsNavItem.active a.colorArrow:after,
.settingsNavItem.active a.colorArrow:after,
.stepsNavItem.active a.colorArrow:after {
	   border-color: transparent transparent transparent #8ac452;
}
body .settingsNavItem a.colorArrow,
.settingsNavItem a.colorArrow {
	color: #fff;
	float: left;
	margin: 0 3px;
	padding: 0 5px 0 7px;
	position: relative;
	text-align: right;
	text-decoration: none;
	@include font-regular();
}
.divError
{
    color: Red;
}
body DIV.settingsNav,
body .settingsNav,
DIV.settingsNav,
.settingsNav {
	margin: 0; 
	overflow: hidden;
	background-color: #e1e1e1;
	/*border-radius: 3px;*/
	/*border: 1px solid #CCCCCC*/
}