@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';
@import 'spritePositions';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Search */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.allegSearchWrapper
{
	position:relative;
	display:inline-block;
	padding: 5px;
}

.allegSearchWrapper .allegSearchIcon{
	position:absolute;
	top: 11px;
	left: 12px;
	width: 20px;
	height: 20px;
}

.allegSearchWrapper .allegSearchIcon{
    background-image: url(Images/SVG/search.svg);
    background-repeat: no-repeat;
}
.allegSearchWrapper .allegSearchClear {
    background-image: url(Images/SVG/cancel.svg);
    background-repeat: no-repeat;
}


.allegSearch
{
    width: 100% !important;
	border: 1px solid #ccc;
	padding-left: 25px !important;
	padding-right: 25px !important;
	box-sizing: border-box !important;
	height: 30px !important;
}

.allegSearchWrapper .allegSearchIcon {
	background-position: 0px 0px;
}

.allegSearchClear
{
    width: 8px;
    height: 8px;
	background-position: 0px 0px;
	position: absolute;
	cursor: pointer;
	display: none;
	top: 18px;
	right: 11px;
}

.allegSearchWrapper input.allegDropdownSearch {
	box-sizing: border-box;
	margin: 0;
	outline: none;
	text-transform: capitalize;
	background-color: #fff;
	border: 1px solid #606060;
	border-radius: 25px !important;
	color: #253746;
}

.allegDropdownSearchClear {
    top: 10px;
}

div.allegDropdownWrapper .allegSearchWrapper
{
   width: 100% !important;
   padding: 4px;
   box-sizing: border-box;
}

div.allegDropdownWrapper .allegDropdownSearchClear {
	right:31px;
	top:15px;
}

.allegSearchHighlight {
    background: yellow;
}

.allegSearchResults {
	background-color: #3b3e40;
	border: 1px solid $color-border-medium;
	border-radius: 0 0 2px 2px;
	border-top: none;
	box-sizing: border-box;
	color: #e5e4e4;
	display: none;
	list-style: none;
	margin: 0;
    max-height: 200px;
    overflow-x: auto;
	padding: 0;
	position: absolute;
	top: 36px;
	width: 100%;

	li {
		cursor: pointer;
		padding: 5px;

		&:hover {
			background-color: $color-background-charcoal;
		}
	}
}


/* DARK THEME */

.allegSearchWrapper.dark {
	.allegSearch {
		background-color: #F6F6F6;
		border: 1px solid #606060;
		color: #253746;
		border-radius: 6px!important;

		&:focus {
			outline: none!important;

			~ .allegSearchIcon {
				background-position: 0px 0px;
			}
		}
	}

	.allegSearchIcon {
		background-position: 0px 0px;
	}

	.allegSearchClear {
		background-position: 0px 0px;
		height: 8px;
		right: 6px;
		top: 18px;
		width: 8px;

		&:hover {
			background-position: 0px 0px;
		}
	}
}