@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

.o-header {
    color: #253746;
    font-size: 20px;
    margin-bottom: 20px;

	&.o-headerDark {
		color: #76797b;
	}

	&.o-headerMedium {
		font-size: 16px;
	}

	.o-contextualHelp {
		margin-top:-3px;
	}
}

.o-contextualHelp {
    margin: 0 10px;
}

.o-bottomButtons {
	border-top: 1px solid #d2d2d2;
	padding: 10px 20px;
}

/* ~~~~~~~~~~~~~~~~~~~~ */
/*         TABS         */
/* ~~~~~~~~~~~~~~~~~~~~ */

.o-tabs {
	padding-top: 2px;
	background: #F6F6F6 0% 0% no-repeat padding-box;
}

.o-tab {
    display:inline-block;
}

.o-activeTab {
	background: #ffffff;
	border-bottom: 4px solid #0058FF;

	.o-tabLink {
		cursor: default;
	}
}

.o-tabLink {
    color: $color-font-default;
    display:inline-block;
    font-size:13px;
    padding: 11.5px 13.5px;
	position:relative;
	border-top-left-radius: 6px;
    border-top-right-radius: 6px;
	border: 1px solid #D3D7DA;
    border-bottom-color: #ffffff;

	&:hover {
		color: $color-font-default;
	}
}

/*****
 *
 * #ALIGNED-BUBBLES
 *
 *****/

.o-alignedBubble {
    background-color: $color-background-charcoal;
    border: 1px solid #aeafb0;
    border-radius: 2px;
    position: absolute;
}

.o-alignedBubble[data-alignment]:before,
.o-alignedBubble[data-alignment]:after {
    content: ' ';
    border: solid transparent;
    height: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
}

.o-alignedBubble[data-alignment='n'],
.o-alignedBubble[data-alignment='nnw'],
.o-alignedBubble[data-alignment='nne'] {
	margin-top: -10px;
}

.o-alignedBubble[data-alignment='n']:before,
.o-alignedBubble[data-alignment='n']:after {
    left: 50%;
    top: 100%;
}


.o-alignedBubble[data-alignment='nne']:before,
.o-alignedBubble[data-alignment='nne']:after {
	top: 100%;
	right: 10px;
}

.o-alignedBubble[data-alignment='nnw']:before,
.o-alignedBubble[data-alignment='nnw']:after {
	top: 100%;
	left: 10px;
}

.o-alignedBubble[data-alignment='n']:before,
.o-alignedBubble[data-alignment='nne']:before,
.o-alignedBubble[data-alignment='nnw']:before {
	border-color: rgba(#aeafb0, 0);
	border-top-color: #aeafb0;
	border-width: 11px;
	margin-left: -11px;
}

.o-alignedBubble[data-alignment='n']:after,
.o-alignedBubble[data-alignment='nne']:after,
.o-alignedBubble[data-alignment='nnw']:after {
	border-color: rgba($color-border-charcoal, 0);
	border-top-color: $color-border-charcoal;
	border-width: 10px;
	margin-left: -10px;
}

.o-alignedBubble[data-alignment='s'],
.o-alignedBubble[data-alignment='ssw'],
.o-alignedBubble[data-alignment='sse']{
	margin-top: 10px;
}

.o-alignedBubble[data-alignment='s']:before,
.o-alignedBubble[data-alignment='s']:after {
    bottom: 100%;
	left: 50%;
}

.o-alignedBubble[data-alignment='sse']:before,
.o-alignedBubble[data-alignment='sse']:after {
	bottom: 100%;
	right: 10px;
}

.o-alignedBubble[data-alignment='ssw']:before,
.o-alignedBubble[data-alignment='ssw']:after {
	bottom: 100%;
	left: 10px;
}

.o-alignedBubble[data-alignment='s']:before,
.o-alignedBubble[data-alignment='ssw']:before,
.o-alignedBubble[data-alignment='sse']:before {
	border-color: rgba(#aeafb0, 0);
	border-bottom-color: #aeafb0;
	border-width: 11px;
	margin-left: -11px;
}

.o-alignedBubble[data-alignment='s']:after,
.o-alignedBubble[data-alignment='ssw']:after,
.o-alignedBubble[data-alignment='sse']:after{
	border-color: rgba($color-border-charcoal, 0);
	border-bottom-color: $color-border-charcoal;
	border-width: 10px;
	margin-left: -10px;
}

.o-alignedBubble[data-alignment='e'] {
	margin-left: 10px;
}

.o-alignedBubble[data-alignment='e']:before,
.o-alignedBubble[data-alignment='e']:after {
	right: 100%;
	top: 50%;
}

.o-alignedBubble[data-alignment='e']:before {
	border-color: rgba(#aeafb0, 0);
	border-right-color: #aeafb0;
	border-width: 11px;
	margin-top: -11px;
}

.o-alignedBubble[data-alignment='e']:after {
	border-color: rgba($color-border-charcoal, 0);
	border-right-color: $color-border-charcoal;
	border-width: 10px;
	margin-top: -10px;
}

.o-alignedBubble[data-alignment='w'] {
	margin-left: -10px;
}

.o-alignedBubble[data-alignment='w']:before,
.o-alignedBubble[data-alignment='w']:after {
	left: 100%;
	top: 50%;
}

.o-alignedBubble[data-alignment='w']:before {
	border-color: rgba(#aeafb0, 0);
	border-left-color: #aeafb0;
	border-width: 11px;
	margin-top: -11px;
}

.o-alignedBubble[data-alignment='w']:after {
	border-color: rgba($color-border-charcoal, 0);
	border-left-color: $color-border-charcoal;
	border-width: 10px;
	margin-top: -10px;
}

 /* ~~~~~~~~~~~~~~~~~~~~~~~~~ */
 /*   BROWSER STYLE TOOLTIP   */
 /* ~~~~~~~~~~~~~~~~~~~~~~~~~ */
	
 .o-browserStyleTooltip {
 	background: $WHITE;
 	border: 1px solid #808080;
 	display: none;
 	max-height: 262px;
 	padding: 10px;
 	position: absolute;
 	width: 300px;
 }

/* ~~~~~~~~~~~~~~~~~~~~ */
/*    BUTTON STYLES     */
/* ~~~~~~~~~~~~~~~~~~~~ */
 .o-btnBar {
	 background: #fff;
	 border-bottom-left-radius: 4px;
	 border-top: 1px solid #d2d2d2;
	 bottom: 0;
	 padding: 10px;
	 position: fixed;
	 width: 100%;
	 z-index: z('btn-footer');

	 .primaryBtn, .secondaryBtn { // Overrides for _helpers.scss
		 height: 25px;
		 margin: 3px;
		 padding: 2px 7px;
	     border-radius: 20px;
		 
	 }
 }

/******
 *
 * # CARDS
 *
 ******/

.o-card {
	background-color: #fff;
	@include border-radius(2px);
	@include box-shadow(0 0 1px rgba(#000,0.1), 0 2px 1px rgba(#000,0.1));
	border: 1px solid #ECECEC;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~ */
/*     SUB-MENU STYLES     */
/* ~~~~~~~~~~~~~~~~~~~~~~~ */
div.o-subMenuWrapper ul { margin: 0 !important; position: relative !important; }

ul.allegDropdownMenu li.o-itemWithSubMenu {

	a:hover + .o-subMenuWrapper,
	a + .o-subMenuWrapper:hover {
		display: block;
	}

	a + .o-subMenuWrapper {
		display: none;
		position: absolute;
		right: 100%;
		top: 0;
		z-index: 1000;
	}

	&.forceOpen a+.o-subMenuWrapper {
		display:block;
	}
}

ul.allegDropdownMenu.hasSubmenus ul {
	overflow:visible;
}

ul.allegDropdownMenu.right li.o-itemWithSubMenu a + .o-subMenuWrapper {
	left: 100%;
}

li.o-itemWithSubMenu a { position: relative; }

li.o-itemWithSubMenu a p.subMenuArrow-fff {
	border-bottom: 4px solid transparent;
	border-left: 4px solid white;
	border-top: 4px solid transparent;
	height: 0;
	right: -5%;
	position: absolute;
	top: 35%;
	width: 0;
}


.o-centeredDetailView {
	width:75%;
	max-width: 900px;
	margin: 0 auto;
	background:#fff;
}

.o-centeredDetailView .allegTable {
    word-break: break-word;
}

.tooltipIcon {
		display: inline-block;
	}
 
		/* tooltipIcon text */
		.tooltipIcon .tooltipIcontext {
			visibility: hidden;
			width: 381px;
			height: 35px;
			color: #253746;
			text-align: left;
			padding: 5px 0;
			background: #F9FAFB 0% 0% no-repeat padding-box;
			box-shadow: 0px 0px 3px #2537460F;
			border: 1px solid #DADADA;
			border-radius: 6px;
			opacity: 1;
			/* Position the tooltipIcon text! */
			position: absolute;
			z-index: 1;
			font-size: 12px;
			margin-left: 19px;
			margin-top: -14px;
			padding-left: 9px;
		}
 
		/* Show the tooltipIcon text when you mouse over the tooltipIcon container */
		.tooltipIcon:hover .tooltipIcontext {
			visibility: visible;
		}
 
	.helpIconDashboard {
		width: 20px;
		height: 20px;
		/*background: url('../../Content/images/info_white.svg');*/
		background-image: url(Images/SVG/info.svg);
		margin-left: 238px;
		top: 5px;
	}
 
 
	.helpImgdrpdw {
		margin-left: 240px;
		margin-top: 1px
	}
 
	.actLstOnhelpImg {
		margin-left: -88px
	}
 
	.helpIconstophintdiv {
		margin-left: -12px;
		margin-top: 4px
	}

