////
/// @group colors
/// @access public
/// @type color
////


///
$mcx-green-mcx: #8ac452;
///
$mcx-green-dark: #499400;
///
$mcx-gray-light-1: #f5f5f5;
///
$mcx-eeeeee: #eee;
///
$mcx-gray-light-2: #b2b2b2;
///
$mcx-gray-light-3: #aaa;
///
$mcx-6d7072: #6d7072;
///
$mcx-54595c: #54595c;
///
$mcx-charcoal: #253746;
///
$mcx-333333: #333;
///
$mcx-red: #b91515;

/* Backgrounds */
///
$color-background-charcoal: #ffffff;
///
$color-background-dark: $mcx-54595c;
///
$color-background-gray-light-1: $mcx-gray-light-1;
///
$color-background-gray-light-3: $mcx-gray-light-3;
///
$color-background-green-mcx: $mcx-green-mcx;
///
$color-background-red: $mcx-red;

/* Border */
///
$color-border-black: $mcx-333333;
///
$color-border-charcoal: $mcx-charcoal;
///
$color-border-dark: $mcx-54595c;
///
$color-border-gray-light-2: $mcx-gray-light-2;
///
$color-border-gray-light-3: $mcx-gray-light-3;
///
$color-border-green-mcx: $mcx-green-mcx;
///
$color-border-light: $mcx-eeeeee;
///
$color-border-medium: $mcx-6d7072;
///
$color-border-red: $mcx-red;

/* Box shadows */
///
$color-box-shadow-gray-light-3: $mcx-gray-light-3;

/* Fonts */
///
$color-font-charcoal: $mcx-charcoal;
///
$color-font-default: #253746; 
///
$color-font-gray-light-1: $mcx-gray-light-1;
///
$color-font-gray-light-2: $mcx-gray-light-2;
///
$color-font-gray-light-3: $mcx-gray-light-3;
///
$color-font-green-dark: $mcx-green-dark;
///
$color-font-green-mcx: #0058FF;
///
$color-font-medium: $mcx-54595c;
///
$color-font-medium2: $mcx-6d7072;
///
$color-font-red: $mcx-red;


/// @deprecated
$allegiance-blue: #369; //This is a deprecated color, and should be replaced

/* Constants */
$WHITE: #fff;
$BLACK: #000;
$RED:   #f00;

//Variabless colors
/*
var col = [
'#2082C0',
'#3289c7',
'#336699',

'#499400',
'#8ac452',
'#8cc652',
'#b1ec78',
'#8cb512',
'#aabb33',

'#aa3333',
'#c65252',
'#dd9797',
'#ff3333',
'#de5021',
'#ff7800',
'#ffc803',
'#face21',
'#ffdd82',
'#fbe3e4',

'#232526',
'#313436',
'#333333',
'#3b3e40',
'#3d4142',
'#3f4040',
'#444444',
'#484c4e',
'#494d4f',
'#54595c',
'#575757',
'#585858',
'#595959',
'#5a5a5a',
'#5b5b5b',
'#656565',
'#6d7072',
'#757575',
'#777777',
'#808080',
'#858585',
'#888888',
'#8b8b8b',
'#8f8f8f',
'#959595',
'#999999',
'#9d9d9d',
'#9ea4a2',
'#9f9f9f',
'#a0a0a0',
'#a1a1a1',
'#a9a9a9',
'#aaaaaa',
'#acacac',
'#aeaeae',
'#b2b2b2',
'#b7b7b7',
'#b8b8b8',
'#bababa',
'#bbbbbb',
'#bcbcbc',
'#bdc0c2',
'#bebebe',
'#bfbfbf',
'#c0c2bd',
'#cccccc',
'#cfcfcf',
'#d2d2d2',
'#d5d5d5',
'#d6d6d6',
'#d8d8d8',
'#dadada',
'#dddddd',
'#e0e0e0',
'#e5e4e4',
'#e6e6e6',
'#e8e8e8',
'#e9e9e9',
'#eaeaea',
'#ebebeb',
'#eeeeee',
'#efefef',
'#f1f1f1',
'#f5f5f5',
'#f7f7f7',
'#f8f8f8',
'#f9f9f9',
'#fafafa'
]

https://jsfiddle.net/5n5t1pwr/17/
*/