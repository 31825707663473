@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Icons & Table Icons */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.blueSettingsIcon,
.blueExportIcon,
.blueRespondentSummaryIcon {
	padding-left:20px;
	line-height:18px;
	color:#313436;
	min-height:18px;
	cursor:pointer;
	font-family: 'ProximaNova-Regular', arial, helvetica;
}

.blueSettingsIcon:before,
.blueExportIcon:before,
.blueRespondentSummaryIcon:before 
{
	background-image: url('Images/icon_sprites.png');
	content: " ";
	position: absolute;
	left:0;
}

.blueRespondentSummaryIcon 
{
	padding-left:30px;
}

.blueSettingsIcon:before {
	background-position:-32px -32px;
	width:17px;
	height:18px;
}

.blueExportIcon:before {
	background-position:-32px -64px;
	width:17px;
	height:18px;
}

.blueRespondentSummaryIcon:before 
{
	background-position:-32px 0;
	width:27px;
	height:18px;
}

.icon1,
.icon2, .settingsIcon, 
.icon3, 
.icon4, .editIcon,
.icon5, .menuIcon,
.icon6, .removeIcon,
.icon7, .addQuestionIcon,
.icon8, .addWeightIcon,
.icon9, .removeAllIcon
{
	cursor:pointer;
	vertical-align:middle;
	display:inline-block;
	background-image: url('Images/icon_sprites.png'); 
}

.icon1, 
.allegTable tr:hover .tableIcons.grayOut .icon1  {
	width: 27px;
	height: 18px;
	background-position:0 0;
}

.icon2,
.allegTable tr:hover .tableIcons.grayOut .icon2,
.settingsIcon,
.allegTable tr:hover .tableIcons.grayOut .settingsIcon {
	background-position:0 -32px;
	width:17px;
	height:18px;
}

.icon3,
.allegTable tr:hover .tableIcons.grayOut .icon3 
{
	background-position:0 -64px;
	width:17px;
	height:18px;
}

.icon4,
.allegTable tr:hover .tableIcons.grayOut .icon4,
.editIcon,
.allegTable tr:hover .tableIcons.grayOut .editIcon
{
	background-position:0 -96px;
	width:21px;
	height:18px;
}

.icon5,
.allegTable tr:hover .tableIcons.grayOut .icon5,
.menuIcon,
.allegTable tr:hover .tableIcons.grayOut .menuIcon
{
	background-position:0 -128px;
	width:18px;
	height:15px;
}

.icon6,
.allegTable tr:hover .tableIcons.grayOut .icon6,
.removeIcon,
.allegTable tr:hover .tableIcons.grayOut .removeIcon
{
	background-position:0 -160px;
	width:14px;
	height:14px;
}

.icon7, 
.allegTable tr:hover .tableIcons.grayOut .icon7,
.addQuestionIcon,
.allegTable tr:hover .tableIcons.grayOut .addQuestionIcon
{
	background-position:0 -224px;
	width:20px;
	height:23px; 

}

.icon8,
.allegTable tr:hover .tableIcons.grayOut .icon8,
.addWeightIcon,
.allegTable tr:hover .tableIcons.grayOut .addWeightIcon
{
	background-position:0 -192px;
	width:24px;
	height:24px;
}

.icon9,
.removeAllIcon,
.allegTable tr:hover .tableIcons.grayOut .icon9,
.allegTable tr:hover .tableIcons.grayOut .removeAllIcon
{
	background-position:0 -256px;
	width:22px;
	height:22px; 
}