@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';
/**
 * Simple style utilities
 */

.u-inline { display: inline }
.u-inlineBlock { display: inline-block }
.u-block { display: block }

/**
 * Whitespace utilities
 */

.u-nowrap { white-space: nowrap }

/**
 * Visibility utilities
 */

/* We always want this to override, so we use an important here */
.u-hidden { display: none !important; }

/**
 * Float utilities
 */
 
.u-floatRight { float: right }