@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Switch */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.allegSwitch {
	height:26px;
	font-size:12px;
	color:$color-font-charcoal;
	cursor:pointer;

	> div,
	> label
	{
		cursor:pointer;
		float:left;
		padding:6px 20px;
		background: #d2d2d2;
		border-right: 1px solid #c0bfbf;
		text-transform: capitalize;
		color: #253746;
		font-size:14px;

		&:first-of-type {
			border-top-left-radius:25px;
			border-bottom-left-radius:25px;
		}

		&:last-of-type {
			border-top-right-radius:25px;
			border-bottom-right-radius:25px;
		}

		&.asSelected {
			cursor:default;
			background: #0058ff;
			color: #ffffff;
		}
	}

	input{
		display:none;
	}
}
.allegSwitch.multi-select > div.asSelected,
.allegSwitch.multi-select > label.asSelected 
{
	cursor:pointer;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Small Switch */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.allegSwitch.small {
	font-size: 11px;
	height: 18px;
}

.allegSwitch.small > div,
.allegSwitch.small > label {
	padding: 5px 10px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Radio Button Switch */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.o-descriptiveRadio {
	color:#333;
	font-size:0; /*Used to kill inline-block spacing issue*/
	display:table-row;

	> label {
		display:table-cell;
		width:450px;
		font-size:12px;
		border-left:0;
		height:100%;
		padding:5px;
		cursor:pointer;
		@include user-select(none);

		input {
			vertical-align: top;
			margin-top: 2px;
		}
	}

	.c-descriptiveRadioTitle {
		display:inline-block;
		font-size:14px;
		margin:0;
		@include font-semibold();
	}

	.c-descriptiveRadioDescription {
		padding: 3px 0;
		margin:0;
		line-height:15px;
	}
}

.c-descriptiveRadioSubWrapper {
	display: inline-block;
	vertical-align: top;
	max-width: 205px;
	margin-left: 5px;
}