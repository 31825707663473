@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
						/* Overlay */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
#overlayAll, #overlayMessageOverlay
{
	height:100%;
}

#tutorialContent, #overlayMessage
{
	top: 0;
	color: #fff;
	text-align: left;
	position:absolute;
	z-index: z('tutorial-modal');
	font-size:18px;
}
#overlayMessage 
{
	top:50px;
	left:0;
	right:0;
	bottom:0;
	margin:0 auto;
	min-width:300px;
	width:100%;
	z-index: z('message');

	h3 {
		padding-top:10px;
	}

	p {
		margin-bottom:10px;
	}

	input {
		vertical-align:middle;
	}
}

#tutorialContent h3, #overlayMessage h3
{
	border:0;
	padding:0;
	margin:0;
	font-size:26px;
}

#tutorialContent h5 
{
	padding: 0;
	margin: 0;
	font-weight: 400;
	font-size: 14px;
}

#tutorialContent {
	&.tutorialStep0,
	&.tutorialStep5
	{
		right:100px;
		top:100px;
		width:480px;
	}

	&.tutorialStep1
	{
		right:15%;
		top:100px;
		width:20%;
	}

	&.tutorialStep2
	{
		left:10px;
		top: 50%;
		width:280px;
	}

	&.tutorialStep3
	{
		left:330px;
		top:300px;
		width:390px;
	}

	&.tutorialStep4
	{
		left:530px;
		top: 200px;
		min-width: 100px;
		max-width: 44%;
	}

}

.tutorialNext, .tutorialCancel, #overlayMessage button
{
	float:none;
	border:1px solid #fff;
	color:#fff;
	font-size:16px;
	background:transparent;
	padding:13px 10px;
	cursor:pointer;
	line-height:0;
	font-weight:700;
	@include border-radius(3px);
	height:31px;
}
#overlayMessage button 
{
	margin-top:10px;
	display:block;
}
.tutorialClose, .closeMessage
{
	background: url(../Reporting/Images/tutorialClose.png);
	width: 25px;
	height:25px;
	position:absolute;
	right: -10px;
	top: -16px;
	cursor:pointer;
}

.tutorialArrows {
	background-image: url(../Reporting/Images/Sprites/tutorialArrows.png);
	background-repeat: no-repeat;
	position:absolute;
}

.tutorialArrows-arrow1 {
	width: 112px;
	height: 38px;
	background-position: -16px -16px;
	left: -144px;
	top: 0;
}

.tutorialArrows-arrow2 {
	width: 99px;
	height: 75px;
	background-position: -16px -86px;
	right:-50px;
	top:-100px;
}

.tutorialArrows-arrow3 {
	width: 99px;
	height: 75px;
	background-position: -16px -193px;
	bottom:-50px;
	right:-50px;
}

.tutorialArrows-arrow4 {
	width: 99px;
	height: 75px;
	background-position: -16px -300px;
	top: -100px;
	left: -30px;
}

.tutorialArrows-arrow5 {
	width: 100px;
	height: 62px;
	background-position: -16px -407px;
	left: -150px;
	top: -30px;
}

.tutorialArrows-arrow6 {
	width: 100px;
	height: 63px;
	background-position: -16px -501px;
	right: -160px;
	top: -30px;
}

#leftOverlay, #rightOverlay, #topOverlay, #bottomOverlay, #overlayAll, #overlayMessageOverlay
{
	top:0;
	position:absolute;
	background:#000;
	@include opacity(0.75);
	z-index: z('tutorial-overlay');
}

#overlayMessageOverlay {
	z-index: z('message-overlay');
}

#topOverlay, #bottomOverlay, #overlayAll, #overlayMessageOverlay {
	width:100%;
}

#fakeOverlay 
{
	position:absolute;
	background:#fff;
	@include opacity(0);
	z-index: z('tutorial-overlay');
}

#borderOverlay {
	position:absolute;
	border:3px solid #fff;
	@include border-radius(5px);
	display:none;
	z-index: z('tutorial-overlay');
	overflow:hidden;
}
#tutorialArrow {
	@include triangle(#676767, up, 7px);
	content: ' ';
	position:absolute;
	top: 5px;
	z-index: z('tutorial-arrow');
}