@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Arrows */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.arrow-up, .arrow-right, .arrow-down, .arrow-left,
.arrow-up-333, .arrow-right-333, .arrow-down-333, .arrow-left-333,
.arrow-up-fff, .arrow-right-fff, .arrow-down-fff, .arrow-left-fff {
	width: 0;
	height: 0;
	@at-root {
		div#{&} {
			display: inline-block;
			vertical-align: middle;

			&.left {
				float: left;

				&.spaced {
					margin-left: 5px;
				}
			}

			&.right {
				float: right;

				&.spaced {
					margin-right: 5px;
				}
			}
		}
	}
}


.arrow-up,
.arrow-up-333,
.arrow-up-fff {
	@include triangle(#333, up, 5px, 4px);
}

.arrow-right,
.arrow-right-333,
.arrow-right-fff {
	@include triangle(#333, right, 5px, 4px);
}

.arrow-down,
.arrow-down-333,
.arrow-down-fff {
	@include triangle(#333, down, 5px, 4px);
}

.arrow-left,
.arrow-left-333,
.arrow-left-fff {
	@include triangle(#333, left, 5px, 4px);
}

.primaryBtn .arrow-down,
.arrow-down-fff {
	border-top: 5px solid #fff;
}

.primaryBtn .arrow-right,
.arrow-right-fff {
	border-left: 5px solid #fff;
}
