@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
						/* Allegiance Counter */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.allegCounterWrapper,
.allegCounter {
	display:inline-block;
}

.allegCountCaption,
.allegCountCount,
.allegCountCount span.partTitle{
	color:#b7b7b7;
}

.allegCountCaption {
	font-size:10px;
	text-transform:uppercase;
}
.allegCountCount {
	font-size:43px;
}

.allegCountCount.active span.partTitle {
	color:#656565;
	font-size:16px;
}

.allegCountCount span.partTitle {
	font-size:16px;
}

.allegCountSection {
	display:inline-block;
	padding:0 5px 0 10px;
	border-left:2px dotted #585858;
}

.allegCounter :first-child {
	border-left:none;
}

.colorRed {
	color:#cf3b28 !important;
}
.colorGreen {
	color:#ab3 !important;
}
.colorYellow {
	color:#fc0 !important;
}
.colorGray {
    color: #b2b2b2 !important;
}
