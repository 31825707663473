@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';
@import 'spritePositions';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Dashboard Dropdown */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.allegDropdownMenu,
.allegMenuItems
{
	list-style:none;
	margin:0;
	padding:0;
	text-align:left;
}
.allegMenuItems {
	background: #fff;
	max-height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	box-shadow: 0 6px 6px 0 #ddd;
}

.allegDropdownMenu 
{
	float:left;
}

body.t-mcx .allegDropdownMenu > li > div {
	padding:0 10px;
}

body.t-mcx .allegDropdownMenu:hover > li > div{

}
body.t-mcx .allegDropdownMenu.forceOpen > li > div {
	background: #F6F6F6 !important;
	color: #ffffff;
}

.allegDropdownMenu.absoluteMenu
{
	float:none;
	width:100%;
}
 
 .allegMenuItems a:hover .removeIcon 
 {
	 display:block;
 }
 .allegMenuItems a .removeIcon 
 {
	 display:none;
	 margin-top:3px;
 }
body.t-mcx .allegDropdownMenu.right ul,
body.t-mcx .allegMenuItems.bordered.right{
	left:0;
    right:auto;
	box-shadow: 9px 0 8px rgba(0,0,0,.1), 0 9px 9px rgba(0,0,0,.1);
}
body.t-mcx .allegDropdownMenu ul,
body.t-mcx .allegMenuItems.bordered {
	right: 7px;
	background: #ffffff;
	border-radius: 6px;
	box-shadow: 3px 0 6px 0 rgba(0,0,0,.43);
}
 
.allegDropdownMenu > li > ul,
.allegDropdownMenu .c-dropdownMenuWrapper,
.allegMenuItems.bordered
{
	max-height:1500%;
	background:#fff;
	list-style:none;
	margin:0;
	padding:0;
	position:absolute;
	border:1px solid #ccc;
	display:none;
	overflow-y:auto;
	min-width: 225px;
	max-width:300px;
	box-shadow: -1px 1px 1px 0 #bbb;
	z-index: z('above-jquery') + 1; /* +1 for BUG-8361 */
}

.allegDropdownMenu .c-dropdownMenuWrapper > ul {
	position:relative;
}

.allegDropdownMenu.tabbed > li > ul,
.allegDropdownMenu.tabbed .c-dropdownMenuWrapper
{
	left:-6px;
}

.allegDropdownMenu.tabbed
{
	padding:2px 5px;
	border:1px solid transparent;
	border-bottom:none;
}
	
.allegDropdownMenu.tabbed:hover
{
	background:#fff;
	border:1px solid #ccc;
	border-bottom:none;
}
	
.allegDropdownMenu {
	&.left {
		> li > ul,
		.c-dropdownMenuWrapper {
			right: 0;
			left: auto;
		}
	}
}

	.allegDropdownMenu.dropBtn li > ul
	{
		margin-left:4px;
	}

	.allegDropdownMenu.t-rounded > li > div {
		border-radius: 2px 2px 0 0;
	}

	.allegDropdownMenu ul li a, .c-reportingSpanCurrentPage, .c-reportingTxtCurrentPage,
	.allegMenuItems li a
	{
		padding:7px;
		color:#fff;
		font-size:14px;
		text-align:left;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.allegDropdownMenu ul li a, .c-reportingSpanCurrentPage, .c-reportingTxtCurrentPage
	{
		max-width: 225px;
	}

	.allegDropdownMenu .c-reportingSpanCurrentPage
	{
		max-width: 250px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.border-hide {
		position: absolute;
		width: 100%;
		padding: 0 5px;
		height: 1px;
		background: #fff !important;
		z-index: z('above-jquery') + 2;
		right: -5px;
		display:none;
	}

	.allegDropdownMenu hr
	{
		margin:0;
		padding:0;
		border-color:#aaa;
		border-width:1px;
		border-top:none;
		border-left:none;
		border-right:none;
	}

	.allegDropdownMenu .top-separator,
	.allegMenuItems .top-separator
	{
		border-top:#aaa 1px solid;
	}

	.allegDropdownMenu .bottom-separator,
	.allegMenuItems .bottom-separator
	{
		border-bottom:#aaa 1px solid;
	}

	.allegDropdownMenu li
	{
		float:left;
		position:relative;
		cursor:pointer;
	}
	.allegDropdownMenu.absoluteMenu li
	{
		position:static;
	}

	.allegDropdownMenu > li,
	.allegDropdownMenu.dropBtn
	{
		float:none;
	}

	.allegDropdownMenu.dropBtn
	{
		display:inline-block;
	}

	.allegDropdownMenu ul li a ,
	.allegMenuItems li a
	{
		font-size:14px;
		padding-right:50px;
		color:#369;
		display:block;
		padding:5px;
		padding-left:0;
		text-decoration:none;
		cursor:pointer;
	}


body.t-mcx .allegDropdownMenu ul li a,
body.t-mcx .allegMenuItems li div a {
	padding: 4px 5px 5px 11px ;
	min-width:150px;
	color: #253746;
	transition: all 0.15s;
}

body.t-mcx .allegDropdownMenu ul li a:hover {
	background: #f6f6f6;
	padding-left: 15px;
}

	body.t-mcx .allegDropdownMenu ul span
	{
		padding:5px 5px 5px 10px;
		z-index: z('zero');
		background: transparent;
	}

	.allegDropdownMenu ul span
	{
		padding:5px 5px 5px 10px;
		z-index: z('zero');
		background:#fff;
	}

	.allegDropdownMenu .dropdown-title div
	{
		width: 95%;
		border-bottom: 1px solid #b30100;
		height: 5px;
		position: absolute;
		top: 10px;
		z-index: z('behind');
	}

	.allegDropdownMenu ul li,
	.allegMenuItems li
	{
		width:100%;
		float:none;
		position: relative;
		border-radius: 6px;
    border-top: 1px solid #ECECEC;
    font-size: 14px!important;
    font-family: IBMPlexSans,arial,Helvetica,Sans-Serif!important;
	}

	.allegDropdownMenu > li > div > a
	{
		color:#369;
		text-decoration:none;
	}

li a.noHover,
li.noHover a {
	color: #253746;
	@include opacity(0.5);

	body.t-suiteLight & {
		color: #9ea4a2 !important;
		@include opacity(1);
	}
}

	li a.noHover:hover,
	li.noHover a:hover
	{
		background:transparent !important;
		cursor:default;
	}

	:hover ul li a.noHover:hover:before,
	li.report-selected a.noHover:before,
	:hover li a.noHover:before,
	:hover ul li.noHover a:hover:before,
	li.report-selected.noHover a:before,
	:hover li.noHover a:before
	{
		color:#fff;
	}

	.allegDropdownMenu.clickToOpen:hover ul,
	.allegDropdownMenu.clickToOpen:hover .border-hide
	{
		display:none;
	}

	.allegDropdownMenu:hover ul,
	.allegDropdownMenu:hover .border-hide,
	.allegDropdownMenu.open ul,
	.allegDropdownMenu.forceOpen ul
	{
		display:block;
	}

	.allegDropdownMenu ul li a,
	.allegMenuItems li a
	{
		padding-left:5px;
		text-decoration:none;
		line-height:18px;
		margin:0;
	}

	.allegDropdownMenu li.dropdown-title,
	.allegDropdownMenu:hover ul li:first-child a,
	.allegDropdownMenu.forceOpen ul li:first-child a,
	.allegDropdownMenu.forceOpen ul li:last-child a,
	.allegDropdownMenu:hover ul li:last-child a,
	.allegDropdownMenu.open ul li:first-child a,
	.allegDropdownMenu.open ul li:last-child a,
	.allegMenuItems li:first-child a,
	.allegMenuItems li:last-child a
	{
		line-height:20px;
	}

	.allegDropdownMenu:hover ul li a:hover,
	.allegMenuItems:hover li a:hover
	{
		padding-left:5px;
		background:#eee;
		margin:0;
	}

	.allegDropdownMenu:hover ul li a:before,
	.allegMenuItems li a:before
	{
		font-size:12px;
		content:"\25b6 \00A0\00A0";
		color:#fff;
	}

	.allegDropdownMenu:hover ul li a:hover:before,
	.allegMenuItems:hover li a:hover:before,
	.allegDropdownMenu li ul li.report-selected a:before
	{
		font-size:12px;
		content:"\25b6 \00A0\00A0";
		color:#ccc;
	}

	.allegDropdownMenu li ul li.dropdown-bolded a:before,
	.allegDropdownMenu:hover ul li.dropdown-bolded a:hover:before,
	.allegTabbedDropdown li .bluePlus:before
	{
		background:url('../Reporting/Images/Sprites/dashboard_sprites.png');
		background-position:0 -320px;
		height:18px;
		width:18px;
		content:' ';
		position:absolute;
		margin-top: 7px;
		margin-left: -21px;
	}

	.allegDropdownMenu li ul li.report-selected a,
	.allegMenuItems li.item-selected a
	{
		margin-left:0;
		padding-left:5px;
		color:#333;
	}

	.allegDropdownMenu li ul li.report-selected a:before,
	.allegDropdownMenu li ul li.report-selected a:hover:before,
	.allegMenuItems li.item-selected a:before,
	.allegMenuItems li.item-selected a:hover:before
	{
		color:#b1c235;
	}

	.allegDropdownMenu:hover ul li.dropdown-title:hover,
	.allegDropdownMenu li ul li.report-selected:hover a,
	.allegMenuItems li.item-selected:hover a
	{
		cursor:default;
	}

	.allegDropdownMenu li ul li.report-selected:hover a,
	.allegMenuItems li.item-selected:hover a   {
		background:none;
	}

	.allegDropdownMenu li ul li.report-selected:hover
	{
		background:#fff;
	}

	.allegDropdownMenu:hover ul li.dropdown-bolded a:hover
	{
		margin-left:0;
	}

	.allegDropdownMenu li ul li.dropdown-bolded
	{
		padding-bottom:3px;
	}

	.allegDropdownMenu li ul li.dropdown-bolded a
	{
		font-weight:700;
		vertical-align:middle;
		margin-left:0;
		padding:5px;
		padding-bottom:0;
	}

	.allegDropdownMenu li ul li.dropdown-title
	{
		padding:5px 0 5px 5px;
		width:95%;
		background:none;
		color:#000;
		font-weight:700;
	}

	.allegDropdownMenu > li.blueArrow {
		padding-right:10px;
	}

	.allegDropdownMenu > li.blueArrow:after {
		content: " ";
		background-image: url('Images/blueDropArrow.png');
		position: absolute;
		width:8px;
		height:7px;
		right:0;
		top:5px;
	}

	div.allegDropdownMenu
	{
		position:relative;
		padding:2px 5px;
		float:left;
	}

	.allegDropdownMenu.t-rounded.openChartTools.openTop > li > div
	{
		border-radius: 0 0 2px 2px;
	}

	.allegDropdownMenu.openChartTools.openTop ul
	{
		top:auto;
		bottom:27px;
		box-shadow: -1px -1px 1px 0 #bbb;
	}

	div.allegDropdownMenu a
	{
		text-decoration:none;
	}

	.allegTableActions {
		width:31px;
		height: 28px;
		position: relative;
	}

	.allegTableActions:hover,
	.allegDropdownMenu:hover .allegTableActions
	{
		background-color: #f1f1f1;
	}

	.allegTableActions div
	{
		background-image: url('Images/Sprites/icon_sprites.png');
		background-position: $icon_sprites-settingsGreen16-left $icon_sprites-settingsGreen16-top;
		height: 16px;
		left: 7px;
		position: absolute;
		top: 5px;
		width: 16px;
	}

	.allegTabbedDropdown
	{
		list-style:none;
		margin:0;
		padding:0;
	}

	.allegTabbedDropdown .topRightItem{
		position: absolute;
		top: 0;
		text-decoration:none;
		line-height:31px;
		font-weight:700;
		right: 9px;
		font-size:12px;
		color:#369;
	}

	.allegTabbedDropdown .topRightItem a:hover
	{
		cursor:pointer;
		text-decoration:none;
		color:#369;
	}

	.allegTabbedDropdown .ui-tabs-anchor
	{
		outline:0;
	}

	.allegTabbedDropdown .ui-tabs
	{
		padding:0;
		position:absolute;
	}

	.allegTabbedDropdown .ui-widget-header
	{
		cursor:default;
	}

	.allegTabbedDropdown .ui-tabs .ui-tabs-panel
	{
		padding:0;
	}

	.allegTabbedDropdown .ui-corner-all
	{
		border-radius:0;
	}
	.allegTabbedDropdown .ui-tabs .ui-tabs-nav
	{
		background:#ebebeb;
	}

	.allegTabbedDropdown .ui-tabs .ui-tabs-nav .ui-state-default
	{
		background:#f5f5f5;
		top:1px;
	}

	.allegTabbedDropdown .ui-tabs .ui-tabs-nav .ui-state-default,
	.allegTabbedDropdown .ui-tabs .ui-tabs-nav .ui-state-active ,
	.allegTabbedDropdown .ui-tabs .ui-tabs-nav .ui-state-default:hover
	{
		border:1px solid #ccc;
	}

	.allegTabbedDropdown .ui-tabs .ui-tabs-nav .ui-state-active
	{
		background:#fff;
		top:1px;
	}

	.allegTabbedDropdown .ui-tabs .ui-tabs-nav .ui-state-active a
	{
		color:#333;
		font-weight:700;
	}

	.allegTabbedDropdown li > div.tabbedContainerWrapper
	{
		display:none;
		position:absolute;
		width:414px;
		height:470px;
		z-index: 91;
	}

	.allegTabbedDropdown li div.tabbedContainer
	{
		display:none;
		position:absolute;
		border:1px solid #ccc;
		width:400px;
		z-index: 90;
	}

	.allegTabbedDropdown li:hover div.tabbedContainer,
	.allegTabbedDropdown li:hover div.tabbedContainerWrapper
	{
		display:block;
	}

	.allegCollapsibleTitle
	{
		font-size: 10px;
		background: #ebebeb;
		padding: 3px;
		padding-left: 5px;
		cursor: pointer;
		margin-bottom: 1px;
		color: #333;
		font-weight:700;
		text-align: left;
	}

	.allegCollapsibleCount
	{
		font-weight:400;
	}

	.allegMenuItems span.allegPrefix{
		padding-right:5px;
		color: #aaa;
		font-size: 11px;
		font-weight: 700;
	}
