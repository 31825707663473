@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
						/* Allegiance Accordion */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.allegAccordionWrapper {
    box-sizing:border-box;
}

.allegAccordion {
    background-color: #f1f1f1;
    border-top:0;
    box-sizing:border-box;
    display:none;
    overflow-y:auto;
    padding:10px;
    width:100%;

    &.o-accordionCheckbox {
        padding-bottom: 0;
    }
}

.allegAccordionHeader {
    background:#d2d2d2;
    box-sizing:border-box;
    cursor:pointer;
    font-weight:700;
    margin-top: 3px;
    padding-left:5px;
    width:100%;

    &:last-of-type {
        margin-bottom: 0;
    }

    &:hover {
        background:#ccc;
    }

    &.default {
        font-size:12px;
        height:27px;
        line-height:27px;

        .arrow-right-333,
        .arrow-down-333 {
            margin-top:10px;
            margin-right: 6px;
        }
    }

    &.small {
        font-size:10px;
        height:19px;
        line-height:15px;

        .arrow-right-333 {
            margin-top:4px;
        }

        .arrow-down-333 {
            margin-top:5px;
        }
    }

    .arrow-right-333 {
        float:left;
        margin:3px;
    }
    .arrow-down-333 {
        float:left;
        margin-right:3px;
    }

    .o-accordionNumSelected {
        float: right;
        padding-right: 5px;
    }
}

/* case mangement ui */
body.t-mcx .allegAccordionHeader {
    @include font-semibold();
}