@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Table */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

body.t-mcx table.allegTable.small,
table.allegTable {
	border-collapse: collapse;
	font-size: 12px;
	text-align: left;
	color: #333;
	width: 100%;
}

.over.old {
	text-decoration: underline;
}

.over {
	text-decoration: underline;
	text-decoration-color: #FFFFFF
}

table.allegTable.small {
	font-size: 10.67px;
}

table.allegTable th {
	background-color: #e5e5e5;
	font-weight: 700;
}

body.t-mcx table.allegTable tr th:first-child {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

body.t-mcx table.allegTable tr th:last-child {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

body.t-mcx table.allegTable.small th,
body.t-mcx table.allegTable.small td,
table.allegTable th,
table.allegTable td {
	padding: 3px 6px;
	border: 0;
	height: 24px;
	border-left: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;
}

body.t-mcx .reportInfoBar table.allegTable.small td:last-child,
body.t-mcx .reportInfoBar table.allegTable.small th:last-child,
.reportInfoBar table.allegTable.small th:last-child,
.reportInfoBar table.allegTable.small td:last-child {
	border-right: 1px solid #d7d7d7;
}

table.allegTable.small th,
table.allegTable.small td {
	padding: 0 10px;
	height: 19px;
}

table.allegTable.small td {
	font-size: 10px;
	height: 17px;
}

table.allegTable.noLeftBorder td,
table.allegTable.noLeftBorder th:not(:first-child) {
	border-left: 0;
}

table.allegTable.noBottomBorder td,
table.allegTable.noBottomBorder th {
	border-bottom: 0;
}

table.allegTable.noBorder td,
table.allegTable.noBorder th {
	border: 0;
}

/*table.allegTable.topBorderOnly td,*/
/*table.allegTable.topBorderOnly th */
/*{*/
/*border:0;*/
/*border-top:1px solid #ccc;*/
/*}*/
body.t-mcx table.allegTable tr th:first-child,
body.t-mcx table.allegTable tr td:first-child {
	padding-left: 15px;
}

table.allegTable tr:nth-child(odd) {
	background-color: #F6F6F6;
}

table.allegTable tr:nth-child(even) {
	background-color: #ffffff;
}

body.t-mcx table.allegTable tr.old:hover,
body.t-mcx table.allegTable tr:nth-child(odd).old:hover,
body.t-mcx table.allegTable tr:nth-child(even).old:hover {
	background-color: #EBEBEB;
}

body.t-mcx table.allegTable tr:hover,
body.t-mcx table.allegTable tr:nth-child(odd):hover,
body.t-mcx table.allegTable tr:nth-child(even):hover {
	background-color: #ECECEC;
}

.allegTable .tableIcons {
	opacity: 0.3;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
	filter: alpha(opacity=30);
	float: right;
	-webkit-transition: opacity .15s ease-in-out;
	-moz-transition: opacity .15s ease-in-out;
	-ms-transition: opacity .15s ease-in-out;
	-o-transition: opacity .15s ease-in-out;
	transition: opacity .15s ease-in-out;
}

.allegTable tr:hover .tableIcons {
	opacity: 1;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	filter: alpha(opacity=100);
}

.allegTable tr .tableIcons .disabled {
	visibility: hidden;
	cursor: default;
}

.allegTable .tableIcons .tableMenu {
	padding: 3px;
	position: relative;
}

.allegTable .tableMenu:hover {
	cursor: pointer;
	background: #eaeaea;
}

.allegTable .tableMenu:hover ul {
	border: 1px solid #ccc;
	border-bottom-width: 2px;
	border-left-width: 2px;
	z-index: z('elevated');
	position: absolute;
	background: #fff;
	display: block !important;
}

.allegTable .tableMenu .allegMenuItems {
	display: none;
}

.allegTable .tableMenu:hover ul li a {
	padding: 5px;
	min-width: 150px;
}

.allegTable tr .tableIcons.grayOut div {
	background-position-x: -32px;
}


.allegTable .tableIcons > * {
	display: inline-block;
	margin-left: 5px;
}

/*.allegTableHeader */
/*{*/
/*background:#e5e5e5;*/
/*height:31px;*/
/*border:1px solid #d7d7d7;*/
/*border-bottom:0;*/
/*}*/

/*.allegTableFixedHeaderWrapper */
/*{*/
/*overflow-y:auto;*/
/*border:1px solid #d7d7d7;*/
/*}*/
/*.allegTableFixedHeaderContainer .allegTable tr th*/
/*{*/
/*background-color:transparent;*/
/*height:0;*/
/*padding:0 !important;*/
/*border:0;*/
/*}*/

/*.allegTableFixedHeaderContainer .allegTable */
/*{*/
/*border-top:0;*/
/*border-bottom:0;*/
/*border-left:0;*/
/*}*/
/*.allegTableFixedHeaderContainer */
/*{*/
/*position:relative;*/
/*}*/
/*.allegTableFixedHeaderContainer .allegTable tr th:first-child > div */
/*{*/
/*border:0;*/
/*}*/
/*.allegTableFixedHeaderContainer .allegTable tr th > div */
/*{*/
/*position:absolute;*/
/*top:0;*/
/*border-left: 1px solid #d7d7d7;*/
/*line-height:31px;*/
/*padding-left:9px;*/
/*}*/
/*.allegTableFixedHeaderContainer .allegTable tr td:first-child*/
/*{*/
/*border-left:0;*/
/*}*/
/*.allegTableFixedHeaderContainer .allegTable tr:last-child td */
/*{*/
/*border-bottom:0;*/
/*}*/


/*tempUi*/

body.t-mcx_old table.allegTable_old {
	border-collapse: collapse;
	border: 0;
	font-size: 14px;
	text-align: left;
	color: #404040;
	width: 100%;
}

body.t-mcx table.allegTable {
	border-collapse: collapse;
	border: 0;
	font-size: 14px;
	text-align: left;
	color: #253746;
	width: 100%;
}

body.t-mcx table.allegTable.small {
	font-size: 10.67px;
}

body.t-mcx .allegTable th {
	font-size: 14px;
	color: #ffffff;
}

body.t-mcx table.allegTable.c-limitedSpace th {
	padding: 3px 6px;
}

body.t-mcx table.allegTable th,
body.t-mcx table.allegTable td {
	padding: 3px 40px 3px 10px;
	border: 0;
	min-width: 32px;
	box-sizing: border-box;
	height: 38px;
}

body.t-mcx table.allegTable tr {
	transition: all 0.15s;
}

body.t-mcx table.allegTable thead tr:hover {
	color: #404040;
}

body.t-mcx table.allegTable th {
	background-color: #253746;
	font-family: IBMPlexSans, arial, helvetica, sans-serif;
	font-weight: normal;
}

body.t-mcx table.allegTable.small th,
body.t-mcx table.allegTable.small td {
	padding: 0 10px;
	height: 19px;
}

body.t-mcx table.allegTable.small td {
	font-size: 10px;
	height: 17px;
}

body.t-mcx table.allegTable.noLeftBorder td,
body.t-mcx table.allegTable.noLeftBorder th:not(:first-child) {
	border-left: 0;
}

body.t-mcx table.allegTable.noBottomBorder td,
body.t-mcx table.allegTable.noBottomBorder th {
	border-bottom: 0;
}

body.t-mcx table.allegTable.noBorder td,
body.t-mcx table.allegTable.noBorder th {
	border: 0;
}

body.t-mcx table.allegTable.topBorderOnly td,
body.t-mcx table.allegTable.topBorderOnly th {
	border: 0;
	border-top: 1px solid #ccc;
}

body.t-mcx .allegTableFixedHeaderContainer {
	position: relative;
}

body.t-mcx table.allegTable {
	position: relative;
}

body.t-mcx .allegTableFixedHeaderContainer table.allegTable {
	position: inherit;
}

body.t-mcx table.allegTable tbody:empty:after,
body.t-mcx .grid.allegTable .slick-rows:empty:after {
	content: attr(data-emptymessage);
	font-size: 22px;
	color: #b1b1b1;
	position: absolute;
	top: 50px;
	left: 12px;
	right: 12px;
	white-space: pre-line;
}

body.t-mcx .grid.allegTable .slick-rows:empty:after {
	top: 11px;
}

body.t-mcx table.allegTable tr:nth-child(odd),
body.t-mcx .allegTable .slick-row.odd {
	//background-color: #FFFFFF;
}

body.t-mcx table.allegTable tr:nth-child(even),
body.t-mcx .allegTable .slick-row.even {
	/*background-color: #f9fafb;*/
}

body.t-mcx .allegTable .tableIcons {
	height: 100%;
	opacity: 0;
	display: table;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=00);
	filter: alpha(opacity=0);
	float: right;
	-webkit-transition: opacity .15s ease-in-out;
	-moz-transition: opacity .15s ease-in-out;
	-ms-transition: opacity .15s ease-in-out;
	-o-transition: opacity .15s ease-in-out;
	transition: opacity .15s ease-in-out;
}

body.t-mcx .allegTable tr:hover .tableIcons,
body.t-mcx .allegTable .slick-row:hover .tableIcons {
	opacity: 1;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	filter: alpha(opacity=100);
}

body.t-mcx .allegTable tr .tableIcons .disabled,
body.t-mcx .allegTable .slick-row .tableIcons .disabled {
	visibility: hidden;
	cursor: default;
}

body.t-mcx .allegTable .tableIcons .tableMenu {
	padding: 3px;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
	position: relative;
}

body.t-mcx .allegTable tr .tableIcons .tableMenu > div,
body.t-mcx .allegTable .slick-row .tableIcons .tableMenu > div {
	position: absolute;
	left: 15px;
	top: 14px;
	transition: all 0.35s;
}

body.t-mcx .allegTable tr:hover .tableIcons .tableMenu > div,
body.t-mcx .allegTable .slick-row:hover .tableIcons .tableMenu > div {
	left: 0px;
}

body.t-mcx .allegTable .tableMenu:hover {
	cursor: pointer;
	background: #eaeaea;
}

body.t-mcx .allegTable .tableMenu:hover ul {
	border-radius: 3px;
	border-top-left-radius: 0;
	border: 0;
	z-index: z('elevated');
	position: absolute;
	background:#ffffff;
	min-width: 160px;
	max-width: 190px;
	left: -1px;
	top: 38px;
	display: block !important;
}

body.t-mcx .allegTable .tableMenu.openTop:hover ul {
	border-radius: 6px;
	border-bottom-left-radius: 0;
	top: auto;
	bottom: 38px;
}

body.t-mcx .allegTable .tableMenu .allegMenuItems {
	display: none;
	border-radius: 6px !important;
	box-shadow: 0 6px 6px 0 #ddd;
}

body.t-mcx .allegTable .tableMenu:hover ul li a {
	padding: 5px 10px;
	min-width: 0;
	color: #253746;
	transition: all 0.15s;
}

body.t-mcx .allegTable .tableMenu ul li a.disabledMenuItem {
	color: #808080;
	cursor: default;
	background: initial !important;
	padding-left: 10px !important;
}

body.t-mcx .allegDropdownMenu:hover ul li a:before,
body.t-mcx .allegMenuItems li a:before,
body.t-mcx .allegMenuItems li:hover a:before {
	content: '';
}

body.t-mcx .allegTable .tableMenu:hover ul li a:hover {
	background: #f6f6f6;
}

body.t-mcx .allegTable tr .tableIcons.grayOut div,
body.t-mcx .allegTable .slick-row .tableIcons.grayOut div {
	background-position-x: -16px;
}

body.t-mcx .allegTable tr .tableIcons:hover div,
body.t-mcx .allegTable .slick-row .tableIcons:hover div {
	background-color: #ffffff;
}


body.t-mcx .allegTable .tableIcons > * {
	display: inline-block;
	margin-left: 0;
}

body.t-mcx .allegTableHeader {
	box-sizing: border-box;
	background: #d2d2d2;
	height: 39px;
	border: 0;
}

body.t-mcx .allegTableFixedHeaderWrapper {
	overflow-y: auto;
	border: 0;
}

body.t-mcx .allegTableFixedHeaderContainer .allegTable tr th {
	background-color: transparent;
	height: 0;
	padding: 0 !important;
	border: 0;
}

body.t-mcx .allegTableFixedHeaderContainer .allegTable {
	border-top: 0;
	border-bottom: 0;
	border-left: 0;
}

body.t-mcx .allegTableFixedHeaderContainer {
	position: relative;
}

body.t-mcx .allegTableFixedHeaderContainer .allegTable tr th:first-child > div {
	border: 0;
}

body.t-mcx .allegTableFixedHeaderContainer .allegTable tr th > div {
	position: absolute;
	top: 13px;
	border-left: 0;
	line-height: 31px;
	padding-left: 9px;
}

body.t-mcx .allegTableFixedHeaderContainer .allegTable tr td:first-child {
	border-left: 0;
}

body.t-mcx .allegTableFixedHeaderContainer .allegTable tr:last-child td {
	border-bottom: 0;
}

body.t-mcx .grid.allegTable,
.reportTableContent .grid.allegTable {
	border: 0;
	font-size: 14px;
	text-align: left;
	color: #404040;
	width: 100%;
}

body.t-mcx .grid.allegTable .tableMenu:hover ul,
.reportTableContent .grid.allegTable .tableMenu:hover ul {
	left: 0;
	border: 1px solid #dadada;
}

body.t-mcx .grid.allegTable .grid-canvas,
.reportTableContent .grid.allegTable .grid-canvas {
	width: 100% !important;
}

body.t-mcx .slick-sort-indicator,
.reportTableContent .slick-sort-indicator {
	position: relative;
	margin: 0;
	vertical-align: middle;
	top: 0;
}

body.t-mcx .grid.allegTable .slick-row,
.reportTableContent .grid.allegTable .slick-row {
	height: 38px;
	transition: all 0.15s;
	border: 0;
}

body.t-mcx .grid.allegTable .slick-row.slick-group,
.reportTableContent .grid.allegTable .slick-row.slick-group {
	height: 8px;
	background: #d2d2d2 !important;
}

body.t-mcx .grid.allegTable .slick-row.slick-group:first-child,
.reportTableContent .grid.allegTable .slick-row.slick-group:first-child {
	display: none;
}

body.t-mcx .grid.allegTable .slick-row .slick-cell:first-child:before,
.reportTableContent .grid.allegTable .slick-row .slick-cell:first-child:before {
	content: '\00a0\00a0\00a0';
}

body.t-mcx .grid.allegTable .slick-row .slick-cell.menuColumn,
.reportTableContent .grid.allegTable .slick-row .slick-cell.menuColumn {
	line-height: normal; /* use normal for IE since initial is not supported */
	overflow: visible;
	font-size: 0;
	margin-left: 0;
}

body.t-mcx .grid.allegTable .slick-row .slick-cell.menuColumn:before,
.reportTableContent .grid.allegTable .slick-row .slick-cell.menuColumn:before {
	content: '';
}

body.t-mcx .grid.allegTable .slick-row .slick-cell.menuColumn .tableIcons,
.reportTableContent .grid.allegTable .slick-row .slick-cell.menuColumn .tableIcons {
	width: 32px;
	font-size: 12px;
}

body.t-mcx .grid.allegTable .slick-row .slick-cell.menuColumn .dragThumb,
.reportTableContent .grid.allegTable .slick-row .slick-cell.menuColumn .dragThumb {
	border: 1px solid #ececec;
	border-radius: 6px;
	box-sizing: border-box;
	width: 8px;
	height: 26px;
	background-color: #0058FF;
	margin: 5px 7px;
	padding-left: 1px;
	padding-top: 6px;
}
body.t-mcx .grid.allegTable .slick-row .slick-cell.menuColumn .dragThumb {

	padding-top: 0px;
	vertical-align: middle;
	width: 14px;
}

body.t-mcx .grid.allegTable .slick-row .slick-cell.menuColumn .dragThumb .icon_sprites,
.reportTableContent .grid.allegTable .slick-row .slick-cell.menuColumn .dragThumb .icon_sprites {
	cursor: move;
}

body.t-mcx .grid.allegTable .slick-row .slick-cell.menuColumn > div,
.reportTableContent .grid.allegTable .slick-row .slick-cell.menuColumn > div {
	display: inline-block;
	vertical-align: middle;
}

body.t-mcx .grid.allegTable .slick-row.old:hover,
.reportTableContent .grid.allegTable .slick-row.old:hover {
	background-color: #ebebeb !important;
}

body.t-mcx .grid.allegTable .slick-row:hover,
.reportTableContent .grid.allegTable .slick-row:hover {
	background-color: #ECECEC !important;
}

body.t-mcx .grid.allegTable .slick-row.slick-group:hover,
.reportTableContent .grid.allegTable .slick-row.slick-group:hover {
	background-color: #d2d2d2 !important;
}

body.t-mcx .grid.allegTable.allegTableReorder .slick-row,
body.t-mcx .grid.allegTable.allegTableReorder .slick-cell,
.reportTableContent .grid.allegTable.allegTableReorder .slick-row,
.reportTableContent .grid.allegTable.allegTableReorder .slick-cell {
	cursor: move;
}

body.t-mcx .grid.allegTable.allegTableReorder .slick-row.slick-group,
body.t-mcx .grid.allegTable.allegTableReorder .slick-group .slick-cell,
.reportTableContent .grid.allegTable.allegTableReorder .slick-row.slick-group,
.reportTableContent .grid.allegTable.allegTableReorder .slick-group .slick-cell {
	cursor: default;
}

body.t-mcx .grid.allegTable .slick-header,
.reportTableContent .grid.allegTable .slick-header {
	font-size: 14px;
}

body.t-mcx .grid.allegTable .slick-cell,
.reportTableContent .grid.allegTable .slick-cell {
	border: 0;
	box-sizing: border-box;
	padding: 0;
	padding-left: 5px;
	z-index: z('initial');
	line-height: 38px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

body.t-mcx .grid.allegTable .slick-cell > div {
	padding: 0;
}

body.t-mcx .grid.allegTable .slick-cell > .cellContent,
.reportTableContent .grid.allegTable .slick-cell > .cellContent {
	margin-right: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
}

body.t-mcx .grid.allegTable .slick-cell .tableIcons,
.reportTableContent .grid.allegTable .slick-cell .tableIcons {
	height: 38px;
	border-collapse: collapse;
	width: 100%;
}

body.t-mcx .grid.allegTable .slick-cell.selected,
.reportTableContent .grid.allegTable .slick-cell.selected {
	background-color: transparent;
}

body.t-mcx .grid.allegTable .slick-header,
.reportTableContent .grid.allegTable .slick-header {
	background-color: #253746;
	border-radius: 5px;
}

body.t-mcx .grid.allegTable .slick-header .slick-header-columns,
.reportTableContent .grid.allegTable .slick-header .slick-header-columns {
	background-color: transparent;
}

body.t-mcx .grid.allegTable .slick-header .slick-header-columns .slick-header-column:first-child:before,
.reportTableContent .grid.allegTable .slick-header .slick-header-columns .slick-header-column:first-child:before {
	content: '\00a0\00a0\00a0';
}

body.t-mcx .grid.allegTable .slick-header .slick-header-columns .slick-header-column.menuColumn,
.reportTableContent .grid.allegTable .slick-header .slick-header-columns .slick-header-column.menuColumn {
	padding: 0;
	margin-left: 0;
}

body.t-mcx .grid.allegTable .slick-header .slick-header-columns .slick-header-column.menuColumn:before,
.reportTableContent .grid.allegTable .slick-header .slick-header-columns .slick-header-column.menuColumn:before {
	content: '';
}

body.t-mcx .grid.allegTable .slick-header .slick-header-column,
.reportTableContent .grid.allegTable .slick-header .slick-header-column {
	border: 0;
	white-space: nowrap;
	box-sizing: border-box;
	height: 38px;
	z-index: z('initial');
	@include font-semibold();
	color: #ffffff;
}

.slick-column-name {
	padding-left: 5px;
}

.reportTableContent .grid.allegTable .slick-header .slick-header-column.ui-draggable {
	cursor: move;
}

body.t-mcx .grid.allegTable .slick-header-column.ui-sortable-handle.ui-state-hover,
.reportTableContent .grid.allegTable .slick-header-column.ui-sortable-handle.ui-state-hover {
	background-color: #e0e0e0;
	cursor: move;
}

body.t-mcx .grid.allegTable .slick-header-column.ui-sortable-handle.ui-state-hover.slick-header-column-active,
.reportTableContent .grid.allegTable .slick-header-column.ui-sortable-handle.ui-state-hover.slick-header-column-active {
	background-color: #8ac452;
	opacity: 0.5;
}

/*Stupid firefox hack, remove this when body.t-mcx is removed*/
.CaseProgramHistoryRegion .slick-header-column,
.caseProgramGrid .slick-header-column,
.AlertSetupRegion .slick-header-column,
.emailTemplatesGrid .slick-header-column,
.caseInboxGrid .slick-header-column {
	box-sizing: border-box;
}

/**
 * These are overrides for .MainListTable
 */
.MainListTable {
	border-right: none;
	border-left: none;
	border-top: none;
	border-bottom: none;
}

.MainListTable th {
	border-bottom: none;
	border-right: none;
}

.MainListTable td {
	border-bottom: none;
	border-right: none;
}

/**
 * Overrides for the Telerik RadGrid which isn't really rad at all.
 * Talk about a blackbox.
 */
body .GridHeader_Default {
	border: none;
}

body .GridRow_Default td,
body .GridAltRow_Default td {
	border: none;
}

body .GridRow_Default {
	background-color: #FFFFFF;
}

body .GridAltRow_Default {
	background-color: #F7f7f7;
}

body .GridHeader_Default:first-child,
body .GridRow_Default > td:first-child,
body .GridAltRow_Default > td:first-child,
body .GridFooter_Default > td:first-child,
body .GridGroupFooter_Default > td:first-child {
	padding-left: 15px;
}

body .GridHeader_Default,
body .GridHeader_Default a {
	font-family: "ProximaNova-Semibold", arial, helvetica, sans-serif;
}

body .GridRow_Default td,
body .GridAltRow_Default td,
body .GridEditRow_Default td,
body .GridFooter_Default td,
body .GridGroupFooter_Default td {
	font-family: "ProximaNova-Regular", arial, helvetica, sans-serif;
}

body .GridPager_Default > td {
	padding: 0px 15px 0px;
}

#HubTable thead tr, #HubTable thead th {
	background: no-repeat padding-box #253746 !important;
	opacity: 1;
	color: #FFFFFF;
}

#divGrid {
	border-radius: 6px 6px 0px 0px !important;
}

table.allegTable.c-limitedSpace thead th {
	background: no-repeat padding-box #253746 !important;
	opacity: 1;
	color: #FFFFFF;
}

.c-mainManageTable {
	border-radius: 6px 6px 0 0 !important;
}
#divGrid_New {
	border-radius: 6px 6px 0 0 !important;
}

.reportTableContent .grid.allegTable .slick-header .slick-header-column,
body.t-mcx .grid.allegTable .slick-header .slick-header-column_New {
	border: 0;
	white-space: nowrap;
	box-sizing: border-box;
	height: 38px;
	z-index: auto;
	font-family: IBM Plex Sans, Regular;
	font-weight: 400 !important;
	padding: 18px 0 0;
	background: no-repeat padding-box #253746 !important;
	opacity: 1;
	color: #FFF;
}

#divGrid a_New {
	color: #0058FF;
}

body.t-mcx .allegTable .slick-row.odd, body.t-mcx table.allegTable tr:nth-child(odd)_New {
	background-color: #F6F6F6;
}

body.t-mcx .allegTable .slick-row.even, body.t-mcx table.allegTable tr:nth-child(even)_New {
	background-color: #fff;
}