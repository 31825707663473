
$icon_sprites-addBlue12-left: -16px;
$icon_sprites-addBlue12-top: -16px;

$icon_sprites-addBlue16-left: -16px;
$icon_sprites-addBlue16-top: -60px;

$icon_sprites-addBlueClear18-left: -16px;
$icon_sprites-addBlueClear18-top: -108px;

$icon_sprites-addGray12-left: -16px;
$icon_sprites-addGray12-top: -158px;

$icon_sprites-addGray20-left: -16px;
$icon_sprites-addGray20-top: -202px;

$icon_sprites-arrowDownGray7-left: -16px;
$icon_sprites-arrowDownGray7-top: -254px;

$icon_sprites-arrowRightGray7-left: -16px;
$icon_sprites-arrowRightGray7-top: -293px;

$icon_sprites-calendarDarkGray13-left: -16px;
$icon_sprites-calendarDarkGray13-top: -332px;

$icon_sprites-calendarDarkGray15-left: -16px;
$icon_sprites-calendarDarkGray15-top: -377px;

$icon_sprites-calendarGreen18-left: -16px;
$icon_sprites-calendarGreen18-top: -424px;

$icon_sprites-closeCircleGray20-left: -16px;
$icon_sprites-closeCircleGray20-top: -474px;

$icon_sprites-closeCircleRed20-left: -16px;
$icon_sprites-closeCircleRed20-top: -526px;

$icon_sprites-collapse9-left: -16px;
$icon_sprites-collapse9-top: -578px;

$icon_sprites-copy10-left: -16px;
$icon_sprites-copy10-top: -619px;

$icon_sprites-copyBlue18-left: -16px;
$icon_sprites-copyBlue18-top: -673px;

$icon_sprites-dragDots15-left: -16px;
$icon_sprites-dragDots15-top: -723px;

$icon_sprites-editBlue18-left: -16px;
$icon_sprites-editBlue18-top: -768px;

$icon_sprites-editGray15-left: -16px;
$icon_sprites-editGray15-top: -818px;

$icon_sprites-editGreen15-left: -16px;
$icon_sprites-editGreen15-top: -865px;

$icon_sprites-expand9-left: -16px;
$icon_sprites-expand9-top: -912px;

$icon_sprites-eyeGray12-left: -16px;
$icon_sprites-eyeGray12-top: -953px;

$icon_sprites-eyeGrayOff12-left: -16px;
$icon_sprites-eyeGrayOff12-top: -997px;

$icon_sprites-eyeGreen12-left: -16px;
$icon_sprites-eyeGreen12-top: -1043px;

$icon_sprites-eyeGreenOff12-left: -16px;
$icon_sprites-eyeGreenOff12-top: -1087px;

$icon_sprites-filterDarkGray12-left: -16px;
$icon_sprites-filterDarkGray12-top: -1133px;

$icon_sprites-filterDarkGray15-left: -16px;
$icon_sprites-filterDarkGray15-top: -1177px;

$icon_sprites-filterGray14-left: -16px;
$icon_sprites-filterGray14-top: -1225px;

$icon_sprites-filterGray16-left: -16px;
$icon_sprites-filterGray16-top: -1271px;

$icon_sprites-greenCheck16-left: -16px;
$icon_sprites-greenCheck16-top: -1319px;

$icon_sprites-helpIconWhite18-left: -16px;
$icon_sprites-helpIconWhite18-top: -1367px;

$icon_sprites-infoGray12-left: -16px;
$icon_sprites-infoGray12-top: -1417px;

$icon_sprites-infoIconGray16-left: -16px;
$icon_sprites-infoIconGray16-top: -1461px;

$icon_sprites-infoIconGreen16-left: -16px;
$icon_sprites-infoIconGreen16-top: -1509px;

$icon_sprites-infoIconOrange16-left: -16px;
$icon_sprites-infoIconOrange16-top: -1557px;

$icon_sprites-infoOrange26-left: -16px;
$icon_sprites-infoOrange26-top: -1605px;

$icon_sprites-menuBlack10-left: -16px;
$icon_sprites-menuBlack10-top: -1663px;

$icon_sprites-menuBlue10-left: -16px;
$icon_sprites-menuBlue10-top: -1705px;

$icon_sprites-moveArrowGray20-left: -16px;
$icon_sprites-moveArrowGray20-top: -1747px;

$icon_sprites-moveArrowGreen20-left: -16px;
$icon_sprites-moveArrowGreen20-top: -1799px;

$icon_sprites-multiColumn9-left: -16px;
$icon_sprites-multiColumn9-top: -1851px;

$icon_sprites-pancakeMenuGray15-left: -16px;
$icon_sprites-pancakeMenuGray15-top: -1892px;

$icon_sprites-pencilBlue12-left: -16px;
$icon_sprites-pencilBlue12-top: -1934px;

$icon_sprites-pencilGray12-left: -16px;
$icon_sprites-pencilGray12-top: -1979px;

$icon_sprites-pencilGreen12-left: -16px;
$icon_sprites-pencilGreen12-top: -2024px;

$icon_sprites-removeBlue13-left: -16px;
$icon_sprites-removeBlue13-top: -2069px;

$icon_sprites-removeBlue18-left: -16px;
$icon_sprites-removeBlue18-top: -2114px;

$icon_sprites-removeGray13-left: -16px;
$icon_sprites-removeGray13-top: -2164px;

$icon_sprites-removeRedClear18-left: -16px;
$icon_sprites-removeRedClear18-top: -2209px;

$icon_sprites-removeXBlue10-left: -16px;
$icon_sprites-removeXBlue10-top: -2259px;

$icon_sprites-removeXBlue14-left: -16px;
$icon_sprites-removeXBlue14-top: -2301px;

$icon_sprites-removeXGray14-left: -16px;
$icon_sprites-removeXGray14-top: -2347px;

$icon_sprites-revertBlue18-left: -16px;
$icon_sprites-revertBlue18-top: -2393px;

$icon_sprites-revertGray18-left: -16px;
$icon_sprites-revertGray18-top: -2443px;

$icon_sprites-searchGreen13-left: -16px;
$icon_sprites-searchGreen13-top: -2493px;

$icon_sprites-searchGrey13-left: -16px;
$icon_sprites-searchGrey13-top: -2538px;

$icon_sprites-settingsGreen16-left: -16px;
$icon_sprites-settingsGreen16-top: -2583px;

$icon_sprites-shareBlue14-left: -16px;
$icon_sprites-shareBlue14-top: -2631px;

$icon_sprites-trash29-left: -16px;
$icon_sprites-trash29-top: -2677px;

$icon_sprites-warningYellow18-left: -16px;
$icon_sprites-warningYellow18-top: -2732px;

$icon_sprites-warningYellow34-left: -16px;
$icon_sprites-warningYellow34-top: -2782px;

$icon_sprites-xGray19-left: -16px;
$icon_sprites-xGray19-top: -2848px;

$icon_sprites-xGray8-left: -16px;
$icon_sprites-xGray8-top: -2899px;

$icon_sprites-xLightGray8-left: -16px;
$icon_sprites-xLightGray8-top: -2939px;

$icon_sprites-xOrange8-left: -16px;
$icon_sprites-xOrange8-top: -2979px;

$icon_sprites-xRed17-left: -16px;
$icon_sprites-xRed17-top: -3019px;

$icon_sprites-xRed19-left: -16px;
$icon_sprites-xRed19-top: -3068px;

$icon_sprites-xRed8-left: -16px;
$icon_sprites-xRed8-top: -3119px;
