@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
					/* Allegiance Loader */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.mcxSpinner,
.allegSpinner {
	width:40px;
	height:40px;
	position:absolute;
	text-align: center;
	margin: auto;
	top:0;
	bottom:0;
	left:0;
	right:0;
	padding:10px;
    z-index: z('loader');
}
.mcxSpinner.border,
.allegSpinner.border 
{
	background:#fff;
	border:1px solid #ccc;
	border-radius:3px;
}
.mcxSpinner:before,
.allegSpinner:before 
{
	content: '';
	display: inline-block;
	vertical-align: middle;
	background-color: rgba(0,0,0,0);
	border: 3px solid rgba(205,50,30,1);
	border-bottom: 3px solid transparent;
	border-left: 3px solid transparent;
	@include animation(spinner 1s infinite);
	@include border-radius(30px);
	width: 30px;
	height: 30px;
}
.mcxSpinner:after,
.allegSpinner:after 
{
	content: '';
	display: inline-block;
	position:absolute;
	top:17px;
	left:20px;
	vertical-align: middle;
	background-color: rgba(#000,0);
	border: 3px solid rgba(205,50,30,1);
	border-top: 3px solid transparent;
	border-right: 3px solid transparent;
	@include animation(spinnerRev 1s infinite);
	border-radius: 15px;
	width: 15px;
	height: 15px;
}

.mcxSpinner:before,
.allegSpinner:before {
	border-color: #0058FF;
	border-bottom: 3px solid transparent;
	border-left: 3px solid transparent;
}
.mcxSpinner:after,
.allegSpinner:after {
	border-color: #0058FF;
	border-top: 3px solid transparent;
	border-right: 3px solid transparent;
}

@include keyframes(spinner) {
	0% {
		@include rotate(0deg);
	}
	100% {
		@include rotate(360deg);
	}
}

@include keyframes(spinnerRev) {
	0% {
		@include rotate(360deg);
	}
	100% {
		@include rotate(0deg);
	}
}

	#headerLoaderWrapper {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(#000,0.5);
	}
    #headerLoaderWrapper .mcxSpinner {
        height:70px;
        width:70px;
    }
    #headerLoaderWrapper .mcxSpinner.border {
        background: #313436;
        border:none;
        border-radius:70px;
    }
    #headerLoaderWrapper .mcxSpinner:before
    {
        border:5px solid #8cc652;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        border-radius: 60px;
        height: 60px;
        width: 60px;
    }
    #headerLoaderWrapper .mcxSpinner:after
    {
        border: 5px solid #8cc652;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-radius: 30px;
        height: 30px;
        left: 25px;
        top: 25px;
        width: 30px;
    }
